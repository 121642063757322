import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import authReducer  from './store/reducers/auth';
import toastMessagesReducer from './store/reducers/message-reducer';
import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import ScrollToTop from './ScrollToTop';
import { HashRouter } from 'react-router-dom'
import { LastLocationProvider } from 'react-router-last-location';
import axios from 'axios';
import {IntlProvider} from "react-intl";
import messages_pagos from "./translations/pagos.json";
import TagManager from 'react-gtm-module'
import App from './App';
import { localStorageClear } from './utils/utils';
import {logout} from './store/actions/index';
import {scyllaDbApi} from "./service/ApiLoanService";


axios.interceptors.request.use(request => {
    let token = localStorage.getItem('token');
    if (!token){
        localStorageClear();
    } else {
        request.headers.common.Authorization = 'Bearer '+ localStorage.getItem('token');
    }

    return request;
}, error => {
    return Promise.reject(error);

});

axios.defaults.baseURL =  process.env.REACT_APP_API_URL + process.env.REACT_APP_API_URL_VERSION;

axios.interceptors.response.use((response) => {
    return response
  }, async function (error) {
    try{
        if (error.response.status === 422) {
            store.dispatch(logout())
        }
    }catch(error){
    }
    return Promise.reject(error);
  });

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('./sw.js')
    .then(function(registration) {
        console.log('Registration successful, scope is:', registration.scope);
    }).catch(function(err) {
        console.log('Service worker registration failed, error:', err);
        console.log(err);
        console.log(err.message);
    });
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
    auth: authReducer,
    message: toastMessagesReducer
});

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

const app = (
    <IntlProvider locale={'en'} messages={messages_pagos}>
        <Provider store={store}>
            <HashRouter>
                <LastLocationProvider>
                    <ScrollToTop>
                        <App></App>
                    </ScrollToTop>
                </LastLocationProvider>
            </HashRouter>
        </Provider>
    </IntlProvider>
);

const tagManagerArgs = {
    gtmId: 'GTM-N9DWWPS',
    dataLayerName: 'PageDataLayer'
}

TagManager.initialize(tagManagerArgs);

ReactDOM.render(app, document.getElementById('root'));

serviceWorker.register();