import React, {Fragment, useRef, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import {switchRole} from '../../shared/roles';
import StripedList from '../../UI/StripedList';
import { Dialog } from 'primereact/dialog';
import { Accordion, AccordionTab } from 'primereact/accordion';
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import ApiLoan from "../../service/ApiLoanService";
import {Toast} from "primereact/toast";

const CLIENT_INFORMATION = [
    {name:'{ClientName}', description: 'Nombre del cliente.'},
    {name:'{ClientLastname}', description: 'Apellido del cliente.'},
    {name:'{SanitizedClientName}', description: 'Nombre del deudor sanitizado.'},
    {name:'{SanitizedClientLastname}', description: 'Apellido del deudor sanitizado.'},
    {name:'{ClientDocumentNumber}', description: 'Documento del cliente.'},
    {name:'{ClientCuil}', description: 'Cuil del cliente.'},
    {name:'{Variable1}', description: 'Información extra 1.'},
    {name:'{Variable2}', description: 'Información extra 2.'},
    {name:'{Variable3}', description: 'Información extra 3.'},

];

const PROVIDER_INFORMATION = [
    {name:'{ProviderName}', description: 'Nombre de la empresa.'},
    {name:'{ProviderAddress}', description: 'Dirección de la empresa.'},
    {name:'{ProviderEmail}', description: 'Email de la empresa.'},
    {name:'{ProviderPhone}', description: 'Teléfono de la empresa.'},
];

const TemplateVariables = ({ onClose, setShowDialog, showDialog, customVariables, canCreateCustomVariables, setNewCustomVariable }) => {
    const [showCreateVariable, setShowCreateVariable] = useState(false);
    const [newVariable, setNewVariable] = useState({
        name: '',
        description: '',
        example: ''
    });

    const [errors, setErrors] = useState({})
    const apiService = new ApiLoan();

    let variables = [...CLIENT_INFORMATION];

    if(['supervisor'].includes(switchRole())){
        if (process.env.REACT_APP_PAGE === 'pip-app'){
            variables = [
                ...variables,
                ...PROVIDER_INFORMATION,
            ]
        }
    }

    const toast = useRef(null);

    const platformVariables = variables.map((reference) => {
        return (
            <Fragment>
                <div>
                    <CopyToClipboard text={reference.name}>
                        <a href={''} onClick={(event) => {event.preventDefault()}}
                           className='btn-actions' style={{padding: '10px 1px 0px 10px', borderRadius: '30px'}}>
                            <span>
                                <FontAwesomeIcon icon={'fa-regular fa-copy'} size="sm" color={'#FD971F'} style={{width: '1.5em', height: '1.5em'}}/>
                            </span>
                        </a>
                    </CopyToClipboard>
                    <b className='pl-3'>{reference.name}:</b> {reference.description} <br/>
                </div>
            </Fragment>
        );
    });

    const providerCustomVariables = customVariables.map((variable) => {
        return (
            <Fragment>
                <div className={"template-value"}>
                    <CopyToClipboard text={variable.name}>
                        <a href={''} onClick={(event) => {event.preventDefault()}}
                           className='btn-actions' style={{padding: '10px 1px 0px 10px', borderRadius: '30px'}}>
                            <span>
                                <FontAwesomeIcon icon={'fa-regular fa-copy'} size="sm" color={'#FD971F'} style={{width: '1.5em', height: '1.5em'}}/>
                            </span>
                        </a>
                    </CopyToClipboard>
                    <b className='pl-3'>{variable.name}:</b> {variable.description} <br/>
                </div>
            </Fragment>
        );
    });

    const newVariableButton = canCreateCustomVariables ? (
        <Button label="Nueva Variable" icon="pi pi-plus" disabled={!canCreateCustomVariables} onClick={() => setShowCreateVariable(true)} className="ui-button-text-icon-primary mr-4"/>
    ) : null

    const header = (
        <div className="custom-modal-header flex justify-content-between flex-wrap">
            <div className="custom-modal-header-title">
                <span className="custom-tag-get-back" onClick={onClose}>
                    <FontAwesomeIcon icon={'arrow-left'}/>
                </span>
                Personalizar
            </div>
            {newVariableButton}
        </div>
    );

    const showAccordionCustomVariables = canCreateCustomVariables ? (
        <AccordionTab header="Variables Personalizadas" disabled={!canCreateCustomVariables}>
            <StripedList items={providerCustomVariables}/>
        </AccordionTab>
    ) : null;

    const body = (
        <Accordion activeIndex={0}>
            <AccordionTab header="Variables Pip">
                <StripedList items={platformVariables}/>
            </AccordionTab>
            {showAccordionCustomVariables}
        </Accordion>
    );

    const updateVariableHandler = (value, field) => {
        setNewVariable(prevState => {
            return {
                ...prevState,
                [field]: value
            }
        })

        let form_errors = {...errors}
        if (form_errors[field]){
            delete form_errors[field]
        }
        setErrors(form_errors)
    }

    const createNewVariableHandler = (e) => {
        e.preventDefault()
        apiService.postResource({
            url: '/provider/custom_variable',
            data: newVariable
        }).then(response => {
            toast.current.show({ severity: 'success', summary: 'Variable', detail: 'Se guardo satisfactoriamente',
                life: 3000});

            setShowCreateVariable(false);
            setNewCustomVariable(true);
        }).catch(error => {
            setErrors(error.response.data);
            toast.current.show({severity:'error', summary: 'Variable', detail: 'Verifique el formulario', life: 3000});
        });
    }

    const getFieldError = (field) => {
        let form = null;
        if (errors[field]){
            form = <small style={{color:'#f44336'}} className="p-error"> {errors[field]} </small>
        }
        return form;
    }

    const variableBody = (
        <div className="formgrid grid p-fluid">
            <div className="field col-12">
                <span className="p-float-label">
                    <InputText id="name" value={newVariable.name} keyfilter="alphanum" required={true}
                               onChange={(e) => updateVariableHandler(e.target.value, 'name')}/>
                    <label htmlFor="name">Nombre</label>
                </span>
                {getFieldError('name')}
            </div>
            <div className="field col-12">
                <span className="p-float-label">
                    <InputText id="description" value={newVariable.description} required={true}
                               onChange={(e) => updateVariableHandler(e.target.value, 'description')}/>
                    <label htmlFor="description">Descripción</label>
                </span>
                {getFieldError('description')}
            </div>

            <div className="field col-12">
                <span className="p-float-label">
                    <InputText id="example" value={newVariable.example}
                               onChange={(e) => updateVariableHandler(e.target.value, 'example')}/>
                    <label htmlFor="example">Ejemplo</label>
                </span>
                {getFieldError('example')}
            </div>

            <div className="field col-12">
                <Button label="Crear" icon="pi pi-plus"
                        onClick={(e) => createNewVariableHandler(e)}
                        className="mx-auto">
                </Button>
            </div>
        </div>
    )

    return (
        <Fragment>
            <Toast ref={toast} />
            <Dialog header={header} visible={showDialog} style={{width: 'auto', minWidth: '50vw'}}
                    onHide={() => setShowDialog(false)}>
                <div className="custom-modal-body">
                    {body}
                </div>
            </Dialog>

            <Dialog header="Crear Variable" visible={showCreateVariable} style={{width: 'auto', minWidth: '50vw'}}
                    onHide={() => setShowCreateVariable(false)}>
                <div className="custom-modal-body">
                    {variableBody}
                </div>
            </Dialog>
        </Fragment>
    );
}

export default TemplateVariables;
