import React, {Fragment, useState, useRef, useContext} from 'react';
import { Toast } from "primereact/toast";
import {getFormattedDateLuxon} from "../../../utils/utils";
import './ConversationListItem.css';

import ConversationPreview from '../ConversationPreview';

import Avatar from 'react-avatar';

import { removeNumbersAndSpecialCharacters, avatarColors } from '../../../utils/utils';
import { CompanyContext } from '../../../context/companyContext';


export default function ConversationListItem(props) {
    const refToast = useRef(null);
    const [showOptions, setShowOptions] = useState(false);
    const [contextMenuIsVisible, setContextMenuIsVisible] = useState(false);
    const {conversation_id, name, last_message, unread_count} = props.data;
    const company_context = useContext(CompanyContext);
    const fulltime = last_message ? getFormattedDateLuxon(last_message.created, company_context.providerTimezone) : '';

    const Fulltime = () => {
        return (
            last_message?.id && (
                <div className='full-time p-as-center' style={{ color: (unread_count ? "#31D1AEE5" : '') }}>
                    {fulltime}
                </div>
            )
        )
    }

    const formatBold = (body) => {
        return <b>{body.replace(/\*(.*?)\*/g, "$1")}</b>
    }
    const formatItalic = (body) => {
        return <i>{body.replace(/_(.*?)_/g, "$1")}</i>
    }

    const formatBody = (body) => {
        if(body.match(/\*(.*?)\*/g)){
            body = formatBold(body)
        }
        else if (body.match(/_(.*?)_/g)){
            body = formatItalic(body)
        }
        return body
    }


    const iconOptions = () => {
        return props.selectedTab !== 2 && (
            <span
                style={{ backgroundColor: 'transparent' }}
                className="badges p-badge p-badge-info p-ml-2"
                onClick={(e) => e.stopPropagation()}
            >
            </span>
        )
    }

    const renderAction = () => {
        return (
            <div className="grid">
                <div className="conversation-title col-8 md-9">
                    <h1 className="conversation-list-item-name">
                        {name}
                    </h1>
                </div>
                <div className="col-4 md-3 p-nogutter">
                    <div className="flex justify-content-end p-nogutter">
                        {full_time}
                        {!unread_count ? null : (
                            <span className="p-badge p-badge-info ml-2" style={{ backgroundColor: "#46d6b7", color: "#fff" }}>
                                {unread_count == null ? 1 : unread_count === 0 ? 1 : unread_count}
                            </span>
                        )}
                    </div>
                </div>
            </div>
        );
    };

    const active_element = props.conversationActive ? 'active' : '';

    const full_time = Fulltime();

    const handleContextMenuVisibility = (e) => {
        if (props.selectedTab === 2) {
            e.preventDefault();
            return;
        }
        if (contextMenuIsVisible) {
            setContextMenuIsVisible(false);
        } else {
            setContextMenuIsVisible(true);
        }
        e.preventDefault();
    }

    const getMessageStyles = (unread_messages) => {
        return unread_messages ? { fontWeight: 'bold', color: '#77b8ff' } : {};
      };
    return (
        <Fragment>
            <div className={`conversation-list-item-internal ${active_element}`} onClick={props.onClick} onContextMenu={(e) => handleContextMenuVisibility(e)} onMouseEnter={() => setShowOptions(true)} onMouseLeave={() => setShowOptions(false)}>
                <div className="flex flex-column p-ac-start conversation-list-avatar-container">
                    <Avatar colors={avatarColors} maxInitials={1} className="conversation-photo p-ac-center" name={removeNumbersAndSpecialCharacters(name)} round={true} size="60" />
                </div>
                <div className="conversation-info">
                    {renderAction()}
                    <div className="grid">
                        <div className="col-8 md-9">
                            <div className="conversation-snippet" style={getMessageStyles(unread_count)}>
                            <ConversationPreview sent={last_message?.sent} read={last_message?.read} type={last_message?.type} delivered={last_message?.delivered} mimetype={last_message?.mime_type} data={formatBody(last_message?.body ?? '')} unread_count={unread_count}
                                    messageType={last_message?.message_type} />
                            </div>
                        </div>
                        <div className="col-4 md-3 flex justify-content-end">
                            {iconOptions()}
                        </div>
                    </div>
                </div>
            </div>
            <Toast ref={refToast} />
        </Fragment>
    );
}