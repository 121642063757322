import React, {useState, useEffect, useRef, Fragment, useContext, useMemo, useCallback} from 'react';
import axios from 'axios';
import { throttle, debounce } from "throttle-debounce";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import jwtDecode from 'jwt-decode';
import notificationSound from '../../../assets/sound/notification_sound.wav';
import ApiLoan from "../../../service/ApiLoanService";
import { listToObject, getLessNumberIndexThan, formatDateMessage } from '../../../utils/utils';
import {
  formatMessage,
  createNewMessageFromClient,
  updateShowedClients,
  formatMessageChat
} from "./ConversationListHelpers"
import { MessageContext } from "../../../context/messageContext";
import { CompanyContext } from '../../../context/companyContext';
import { switchRole } from "../../../shared/roles";
import DialogGeneric from "../../../UI/DialogGeneric";
import CustomTabHeaders from '../../../UI/CustomTabHeaders';
import ConversationSearch from '../ConversationSearch';
import ConversationListItem from '../ConversationListItem';
import Toolbar from '../Toolbar';
import ToolbarButton from '../ToolbarButton';
import {Dropdown} from "primereact/dropdown";
import './ConversationList.scss';
import MessageNoteReminder from "../MessageNote/MessageNoteReminder";
import {MessageNoteContext} from "../../../context/messageNoteContext";
import {Toast} from "primereact/toast";
import { DateTime } from 'luxon';
import CustomSpinner from "../../../UI/CustomSpinner";
import { Menu } from 'primereact/menu';
import { TabView, TabPanel } from 'primereact/tabview';
import ConversationContact from "../ConversationContact/ConversationContact";
import {PHONE} from "../../../shared/contactTypes";
import { useParams } from "react-router-dom";
import { phone } from "phone";
import MessagesNoteModal from "../MessageNote/MessagesNoteModal";
import { nameMimetype } from '../../../utils/utils';
import ClientFormCustom from '../../Clients/Client/ClientFormCustom';
import { Dialog } from 'primereact/dialog';
import InfiniteScroll from 'react-infinite-scroller';
import { useAudioPlayer } from '../../../context/AudioPlayerContext';
import Avatar from 'react-avatar';
import { Button } from 'primereact/button';
import ResultNotFount from '../../../UI/ResultNotFount';
import MessageNotFount from '../../../UI/MessageNotFount';
import ConversationSkeleton from '../ConversationSkeleton';
import { getPhoneInfo } from '../../../shared/utility';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import ChannelSelector from '../../ChatChannelContainer/ChannelSelector';
import ChatListItem from "../ChatListItem";
import { VirtualScroller } from 'primereact/virtualscroller';
import ResultNotMoreFount from '../../../UI/ResultNotMoreFount';
import LoadMoreResults from '../../../UI/LoadMoreResults';
import { useFlag } from  '../../../utils/flags';

function useMergeState(initialState) {
  const [state, setState] = useState(initialState);
  const setMergedState = (newState) => {
    return setState((prevState) => {
      let currentState = {
        conversations: newState.conversations,
        chats: newState.chats,
        messagesByContact: newState.messagesByContact,
        searchLoading: newState.searchLoading,
        page: newState.page,
      }
      return currentState
    });
  }
  return [state, setMergedState];
}

const ConversationList = React.memo(function ConversationList(props) {
  const [conversationState, setConversationState] = useMergeState({
    conversations: [],
    chats: [],
    messagesByContact: [],
    searchLoading: false,
    page: 1,
  });

  const [clientsState, setClientsState] = useState({
    showedClients: [],
    clients: [],
    searchLoading: false,
    page: 1,
  });
  const [paginating, setPaginating] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadinglist, setLoadinglist] = useState(false);
  const [loadingsearch, setLoadingsearch] = useState(false);
  const [lastMessageReached, setLastMessageReached] = useState(false);
  const [lastClientReached, setLastClientReached] = useState(false);
  const history = useHistory();
  const [selectedTab, setSelectedTab] = useState(0);
  const [assign, setAssign] = useState(false);
  const [notAssigned, setNotAssigned] = useState(false);
  const [recentlyTransferred, setRecentlyTransferred] = useState(false);
  const [showUnreads, setShowUnreads] = useState(false);
  const [filterUnreads, setFilterUnreads] = useState(false);
  const [showArchivedMessages, setArchivedMessages] = useState(false);
  const [filterArchivedMessages, setFilterArchivedMessages] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedUserTmp, setSelectedUserTmp] = useState([]);
  const [selectedTagTmp, setSelectedTagTmp] = useState([]);
  const [dialogCustomTag, setDialogCustomTag] = useState(false);
  const [updateModalInfo, setUpdateModalInfo] = useState(false);
  const [valueDialog, setValueDialog] = useState(null);
  const [modalInfo, setModalInfo] = useState(null);
  const [selectedCustomTags, setSelectedCustomTags] = useState([]);
  const [dialogNoteReminder, setDialogNoteReminder] = useState(false);
  const apiService = new ApiLoan();
  const CancelTokenConversationRequest = axios.CancelToken;
  const conversationRequest = CancelTokenConversationRequest.source();
  const message_context = useContext(MessageContext);
  const company_context = useContext(CompanyContext);
  const [userClaims, setUserClaims] = useState([]);
  const [supervisor, setSupervisor] = useState(false);
  const [conversationListUsers, setConversationListUsers] = useState(null);
  const [conversationListUsersOrigin, setConversationListUsersOrigin] = useState(null);
  const [showConversationListUsers, setShowConversationListUsers] = useState(false);
  const [selectedUserList, setSelectedUserList] = useState(null);
  const [selectedTagList, setSelectedTagList] = useState(null);
  const [selectedTag, setSelectedTag] = useState(null);
  const [showConversationListTags, setShowConversationListTags] = useState(false);
  const [conversationActive, setConversationActive] = useState(null);
  const [chatActive, setChatActive] = useState(null);
  const [conversationListTags, setConversationListTags] = useState(null);
  const [itemMenuFilter, setItemMenuFilter] = useState([]);
  const [itemMenuMore, setItemMenuMore] = useState(false);
  const [conversationListGroups, setConversationListGroups] = useState(null);
  const [conversationListGroupsOrigin, setConversationListGroupsOrigin] = useState(null);
  const socket = company_context.socket;
  const [showConversationListGroups, setShowConversationListGroups] = useState(null);
  const [selectedGroupList, setSelectedGroupList] = useState(null);
  const [selectedGroupTmp, setSelectedGroupTmp] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [showClientView, setShowClientView] = useState(false);
  const num_results = 40;
  const searchValueMinLength = 3
  const { setIsTopBarVisible } = company_context;
  const [height, setHeight] = useState(window.innerWidth);
  const player = document.getElementById('globalAudioPlayer');
  const { audioEnded, setAudioEnded, isAudioVisible, setIsAudioVisible } = useAudioPlayer();
  const [ loadingConversationQuick, setLoadingConversationQuick ] = useState(false);

  const searchV2 = useFlag("search-v2", false);

  const [activeIndex, setActiveIndex] = useState(0);
  const [copyManager, setCopyManager] = useState({
    firstConversations: null,
    searchedConversations: null,
    searchedChats: null,
  });
  const [searchedConversations, setSearchedConversations] = useState([]);
  const [putupConversationSent, setPutupConversationSent] = useState(false);

  let hasPinUp = !(company_context.privateInbox && ['supervisor'].includes(switchRole()))
  const [searchConversationContactValue, setSearchConversationContactValue] = useState({
    phone_number: '',
    country_prefix: '',
    phone_valid: false
  });
  const [loadingSearchConversationContact, setLoadingSearchConversationContact] = useState(false);

  const toast = useRef(null);
  const refMenu = useRef(null);
  const showedClientsRef = useRef(clientsState.showedClients);
  const searchValueRef = useRef("");
  const searchValueRefDebounced = useRef(["",""]);

  const historyParams = useParams();

  const {selectedClient, selectedMessageNote, globalClients, expirationNotes,
    messageNotes, errorNotes, loadingConversation, selectedContact,
    activeMessageNoteModal, clientFullName, updateMessageNotes, updateErrorNotes, updateExpirationNotes,
    updateLoadingConversation, setActiveMessageNoteModal} = useContext(MessageNoteContext);
    const selectedPhoneContact = useRef(null);

  useEffect(() => {
    setIsTopBarVisible(true)
  }, []);

  useEffect(() => {
    showedClientsRef.current = clientsState.showedClients;
  }, [clientsState.showedClients]);

  useEffect(() => {
    searchValueRef.current = searchValue;

    if (searchValue.trim().length < searchValueMinLength) {
      searchValueRefDebounced.current[0] = searchValue;
      searchValueRefDebounced.current[1] = searchValue;

      debounceTimerRef.current = null;
      if (cancelTokenRef.current) cancelTokenRef.current.cancel();
    }
  }, [searchValue]);

  function sendNotificationSound(){
    const audio = new Audio(notificationSound);
    audio.addEventListener('canplaythrough', () => {
      audio.play();
    });
  }

  function showNotification(title, options, message) {
    if ('serviceWorker' in navigator && isMobile() && localStorage.getItem('token')) {
      if (Notification.permission === 'granted') {
        navigator.serviceWorker.ready.then(function(registration) {
          registration.showNotification(title, options);
        });
      }
    }
    if ('Notification' in window && !isMobile() && localStorage.getItem('token')) {
      if (Notification.permission === 'granted') {
        let notification = new Notification(title, options);
        notification.onclick = function(event) {
          event.preventDefault();
          message.client.name = message.client.firstname + ' ' + message.client.lastname;
          props.onSelectClientHandler(message.client);
          setConversationActive(message.client.id);
          window.focus();
        };
      };
    }
  }

  const refresh_transferred_chats = () => {
    setTimeout(() => message_context.updateDialogReassignMessages(false), 500)
    setConversationState({
      conversations: [],
      messagesByContact: [],
      searchLoading: false,
      page: 1,
    })
    refreshConversations();
  }
  const refreshConversations = () => {
    let cancelToken = axios.CancelToken.source()
    getConversationsForEntry(cancelToken)
    getClients();
    return () => {
      conversationRequest.cancel()
    }
  }

  useEffect(() => {
    if (putupConversationSent) return;
    refreshConversations();
  }, [company_context.privateInbox, company_context.privateGroup, putupConversationSent]);

  const isMobile = () => {
    return window.innerWidth <= 750;
  };

  useEffect(() => {
    if (selectedTag) message_context.updateSelectedCustomTags([selectedTag])
    else message_context.updateSelectedCustomTags([])
  }, [selectedTag]);

  useEffect(() => {
    setConversationListTags(message_context.customTags);
  }, [message_context.customTags]);

  useEffect(() => {
    let newGroups = [];
    if(!company_context.privateInbox || ['admin', 'supervisor'].includes(switchRole())){
      newGroups = message_context.groups
    } else {
      newGroups = message_context.groups.filter(group => {
        return group.users.some(user => user.username === localStorage.getItem('username'));
      });
    }
    setConversationListGroups(newGroups);
    setConversationListGroupsOrigin(newGroups);
  }, [message_context.groups])

  useEffect(() => {
    const updateWindowDimensions = () => {
      const newHeight = window.innerWidth;
      setHeight(newHeight);
    };

    window.addEventListener("resize", updateWindowDimensions);

    return () => window.removeEventListener("resize", updateWindowDimensions);

  }, []);

  useEffect(() => {
    let selectedTags = []
    selectedTagTmp.map(element => {
      selectedTags.push(element.id);
    })
    setSelectedCustomTags(selectedTags);
  }, [selectedTagTmp]);

  useEffect(()=>{
    let items = [];
    let userFilter =  {
      label:
      <i>
          <FontAwesomeIcon icon={"fa-regular fa-user"} style={{width: '1.3em', height: '1.3em'}}/>
          <span className="menu-font-awesome-icon"> Filtro por operador </span>
      </i>,
      command: () => {
        filterConversationListByUser()
      }
    }
    let tagFilter = {
      label:
      <i>
          <FontAwesomeIcon icon={"fa-regular fa-tag"} style={{width: '1.3em', height: '1.3em'}}/>
          <span className="menu-font-awesome-icon"> Filtro por etiqueta </span>
      </i>,
      command: () => {
        filterConversationListByTag()
      }
    }
    let groupFilter = {
      label:
      <i>
          <FontAwesomeIcon icon={"fa-regular fa-users"} style={{width: '1.3em', height: '1.3em'}}/>
          <span className="menu-font-awesome-icon"> Filtro por grupo </span>
      </i>,
      command: () => {
        filterConversationListByGroup()
      }
    }
    let assigned = {
      label:
      <i>
          <FontAwesomeIcon icon={"fa-regular fa-user-check"} style={{width: '1.3em', height: '1.3em'}}/>
          <span className="menu-font-awesome-icon"> Filtrar asignado </span>
      </i>,
      command: () => {
        setSelectedTab(4);
      }
    }
    let notAssigned = {
      label:
      <i>
          <FontAwesomeIcon icon={"fa-regular fa-user-xmark"} style={{width: '1.3em', height: '1.3em'}}/>
          <span className="menu-font-awesome-icon"> Filtrar no asignado </span>
      </i>,
      command: () => {
        setSelectedTab(5);
      }
    }
    let recentlyTransferred = {
      label:
        <i>
          <FontAwesomeIcon icon={"fa-regular fa-share"} style={{ width: '1.3em', height: '1.3em' }} />
          <span className="menu-font-awesome-icon"> Filtrar derivado </span>
        </i>,
      command: () => {
        setSelectedTab(6);
      }
    }
    const role = switchRole();
    const isAudience = role === 'audience';
    const commonFilters = [tagFilter, groupFilter, recentlyTransferred];

    if (supervisor || (isAudience && !company_context.privateGroup)){
      items.push(userFilter, ...commonFilters);
    } else {
      items.push(...commonFilters);
    }

    if (!company_context.privateInbox || ['admin', 'supervisor'].includes(role)) {
      items.push(...[assigned, notAssigned]);
    }
    setItemMenuFilter(items);
  },[company_context.privateInbox, company_context.privateGroup, supervisor])

  useEffect(() => {
    setLastClientReached(false);
    setLastMessageReached(false);
  }, [searchValue, selectedUser, showUnreads, showArchivedMessages, notAssigned]);

  useEffect(() => {
    socket.on('delete_message_reaction-' + localStorage.getItem('provider_id'), removeMessageReaction);
    socket.on('update_message_reaction-' + localStorage.getItem('provider_id'), updateMessage);
    socket.on('new_messages-' + localStorage.getItem('provider_id'), addNewMessageByContact);
    socket.on('messenger-events-' + localStorage.getItem('provider_id'), updateMessage);
    socket.on('add-custom-tags-to-client-' + localStorage.getItem('provider_id'), addClientCustomTags);
    socket.on('delete-custom-tag-' + localStorage.getItem('provider_id'), deleteClientCustomTag);
    socket.on('client-assignation-' + localStorage.getItem('provider_id'), assignClient);
    socket.on('client-removal-' + localStorage.getItem('provider_id'), removeClient);
    //socket.on('update-custom-tag-' + localStorage.getItem('provider_id'), updateClientCustomTag)
    socket.on('custom-tag-csv-' + localStorage.getItem('provider_id'), customTagsCSV);
    socket.on('new_note-' + localStorage.getItem('provider_id'), addNewNote);
    socket.on('delete_reminder-' + localStorage.getItem('provider_id'), deleteNoteReminder);
    socket.on('seen_reminder-' + localStorage.getItem('provider_id'), seenNoteReminder);
    socket.on('update_status_message-' + localStorage.getItem('provider_id'), updateStatusMessage);
    socket.on('update_message-' + localStorage.getItem('provider_id'), updateMessage);
    socket.on('update_client-' + localStorage.getItem('provider_id'), updateClient);
    socket.on('transferred_chats-' + localStorage.getItem('provider_id'), refresh_transferred_chats);
    return () => {
        socket.off('delete_message_reaction-' + localStorage.getItem('provider_id'), removeMessageReaction);
        socket.off('update_message_reaction-' + localStorage.getItem('provider_id'), updateMessage);
        socket.off('new_messages-' + localStorage.getItem('provider_id'), addNewMessageByContact);
        socket.off('messenger-events-' + localStorage.getItem('provider_id'), updateMessage);
        socket.off('add-custom-tags-to-client-' + localStorage.getItem('provider_id'), addClientCustomTags);
        socket.off('delete-custom-tag-' + localStorage.getItem('provider_id'), deleteClientCustomTag);
        socket.off('client-assignation-' + localStorage.getItem('provider_id'), assignClient);
        socket.off('client-removal-' + localStorage.getItem('provider_id'), removeClient);
        //socket.off('update-custom-tag-' + localStorage.getItem('provider_id'), updateClientCustomTag)
        socket.off('custom-tag-csv-' + localStorage.getItem('provider_id'), customTagsCSV);
        socket.off('new_note-' + localStorage.getItem('provider_id'), addNewNote);
        socket.off('delete_reminder-' + localStorage.getItem('provider_id'), deleteNoteReminder);
        socket.off('seen_reminder-' + localStorage.getItem('provider_id'), seenNoteReminder);
        socket.off('update_status_message-' + localStorage.getItem('provider_id'), updateStatusMessage);
        socket.off('update_message-' + localStorage.getItem('provider_id'), updateMessage);
        socket.off('update_client-' + localStorage.getItem('provider_id'), updateClient);
        socket.off('transferred_chats-' + localStorage.getItem('provider_id'), refresh_transferred_chats);
    }
  },[conversationState.messagesByContact, conversationState.conversations, globalClients]);


  useEffect(() => {
    if (updateModalInfo && valueDialog !== null && dialogCustomTag) {
      setModalInfo(<DialogGeneric visible={dialogCustomTag}
                                  withoutCancel={false}
                                  closable={false}
                                  setVisible={setDialogCustomTag}
                                  closeModal={true} {...valueDialog}  />);
    }
  }, [updateModalInfo, valueDialog, dialogCustomTag]);

  useEffect(() => {
    if (updateModalInfo && valueDialog !== null && message_context.dialogReassignMessages) {
      setModalInfo(<DialogGeneric visible={message_context.dialogReassignMessages}
                                  closable={false}
                                  setVisible={message_context.updateDialogReassignMessages}
                                  closeModal={true} {...valueDialog}  />);
    }
  }, [updateModalInfo, valueDialog, message_context.dialogReassignMessages]);

  useEffect(() => {
    if (!dialogCustomTag && !message_context.dialogReassignMessages){
      props.setButtonsActivated(false);
      if (modalInfo !== null) {
        setModalInfo(null);
      }
      if (updateModalInfo){
        setUpdateModalInfo(false);
      }
      if (valueDialog !== null){
        setValueDialog(null);
      }
    }

  }, [modalInfo, dialogCustomTag, message_context.dialogReassignMessages]);

  useEffect(() => {
    let role = switchRole();
    if (role === 'supervisor') {
      setSupervisor(true);
    }
    getUsers();
  },[]);

  useEffect(() => {
    if (message_context.closeModal){
      setDialogCustomTag(!dialogCustomTag)
      message_context.updateCloseModal(false);
    }
  }, [message_context.closeModal]);

  useEffect(() => {
    if(!showUnreads){
      setFilterUnreads(false);
    }
  }, [showUnreads]);

  useEffect(() => {
    if (selectedClient !== null && globalClients) {
      setActiveMessageNoteModal(true);
      updateLoadingConversation(false);
    }
  }, [selectedClient, globalClients, lastMessageReached, selectedTab, conversationState.conversations]);

  useEffect(() => {
    if (errorNotes !== null && dialogNoteReminder){
      toast.current.show({severity: 'error', summary: 'Nota/Recordatorio', detail: errorNotes,
        sticky: true});
      updateErrorNotes(null);
    }
  }, [errorNotes, dialogNoteReminder]);


  useEffect(() => {
    if (searchConversationContactValue.phone_number === ''){
      setClientsState({
        ...clientsState,
        showedClients: [...clientsState.clients],
        page: 1
      })
    }
  }, [searchConversationContactValue.phone_number])

  useEffect(() => {
    if (loadingSearchConversationContact && !loading && !loadingsearch){
      if (clientsState.showedClients.length === 1){
        props.onSelectClientHandler(clientsState.showedClients[0].client);
        setConversationActive(clientsState.showedClients[0].client.id);
        setLoadingSearchConversationContact(false);
      } else if (clientsState.showedClients.length === 0){
        const apiService = new ApiLoan();
        let phone_number = searchConversationContactValue.country_prefix + searchConversationContactValue.phone_number
        apiService.postResource({
          url: '/audiences',
          data: {
            document_number: phone_number,
            firstname: phone_number,
            lastname: phone_number,
            cuil: phone_number,
            renamed: true,
            contacts:  [
                {
                  id: null,
                  contact_type_id: PHONE,
                  value: {
                    country_prefix: searchConversationContactValue.country_prefix,
                    value: searchConversationContactValue.phone_number,
                    validate_contact: false
                  }
                }
            ]
          }
        }).then(response => {
          let contacts = [{
            client_id: response.data.contacts[0].client_id,
            contact_type_id: response.data.contacts[0].contact_type_id,
            country_prefix: response.data.contacts[0].country_prefix,
            value: response.data.contacts[0].value,
          }]
          let new_client = [{
            photo: 'assets/layout/images/profile.png',
            name: response.data.firstname,
            text: '',
            seen: true,
            client: {
              id: response.data.id,
              name: response.data.firstname,
              client_custom_tags: [],
              contacts: contacts,
            },
            client_id: response.data.id,
            id: response.data.id
          }];

          setClientsState({
            ...clientsState,
            showedClients: [
              ...new_client
            ],
            clients: [
              ...clientsState.clients,
              ...new_client
            ]
          })
          setSearchConversationContactValue((prevState) => ({
            ...prevState,
            phone_valid: true
          }));
        }).catch((error) => {
          setSearchConversationContactValue((prevState) => {
            return {
                ...prevState,
                phone_valid: false
            }
          });
          toast.current.show({severity: 'error', summary: 'Inicio de conversación rápida', detail: 'Numero inválido', sticky: true, life: 3000});
        }).finally(() => {setLoadingSearchConversationContact(false);});
      }
    }

  }, [loadingSearchConversationContact, clientsState.showedClients, conversationState.conversations])

  useEffect(() => {
    if (message_context.selectedPhoneContact && !loadingSearchConversationContact && !message_context.loadingSearchSelectedPhoneContact){
      setSearchConversationContactValue({
          phone_number: message_context.selectedPhoneContact.phone_number,
          country_prefix: message_context.selectedPhoneContact.country_prefix,
          phone_valid: true
      })
      message_context.updateLoadingSearchSelectedPhoneContact(true);
    }
  }, [message_context.selectedPhoneContact, loadingSearchConversationContact, message_context.loadingSearchSelectedPhoneContact])

  useEffect(() => {
    if (message_context.loadingSearchSelectedPhoneContact && searchConversationContactValue.phone_number !== ''){
      setLoadingConversationQuick(true);
      setTimeout(() => {
        searchConversationContactHandler();
        setLoadingConversationQuick(false);
      }, 3000);
      message_context.updateSelectedPhoneContact(null);
      message_context.updateLoadingSearchSelectedPhoneContact(false);
    }
  }, [message_context.loadingSearchSelectedPhoneContact, searchConversationContactValue.phone_number])

  const onSelectedPhoneContact = () => {
    let phoneInfo = getPhoneInfo(selectedPhoneContact?.current);
    let contact = {
        phone_number: selectedPhoneContact?.current || '',
        country_prefix: '',
        phone_valid: false,
    }

    if (phoneInfo){
        contact.country_prefix = phoneInfo.country_code;
        contact.phone_number = phoneInfo.number;
        contact.phone_valid = true
    }
    message_context.updateSelectedPhoneContact(contact);
    selectedPhoneContact.current = null;
    history.replace('/')
  }

  useEffect(() => {
    let { phone_number } = historyParams;

    if (phone_number !== undefined){
        selectedPhoneContact.current = phone_number;
        onSelectedPhoneContact();
        setSelectedTab(2);
    }

  }, [historyParams]);

  const getConversationsByContactPipUp = (page) => {
    return apiService.getResources({
        url: '/conversations',
        page: page,
        results_per_page: num_results,
        cancelToken: conversationRequest.token,
        archived: showArchivedMessages,
        pinup_messages: true,
    });
  };

  /**
   * Fetches conversations by contact with pagination.
   *
   * @param {number} page - The page number to retrieve.
   * @returns {Promise<Object>} A promise that resolves to the response from the API.
   */
  const getConversationsByContact = (page, params) => {

    let archivedValue = null
    if (params && params['archived']) archivedValue = showArchivedMessages

    return apiService.getResources({
        url: '/conversations',
        page: page,
        results_per_page: num_results,
        cancelToken: conversationRequest.token,
        archived: archivedValue,
        pinup_messages: false,
    });
  };

  const getMoreMessagesByContact = (page, fromId, showUnreads, notAssigned, showArchivedMessages) => {
    setLoadingsearch(true);
    setLoading(true);

    let params = {
      page: page,
      results_per_page: num_results,
      from_id: fromId,
      cancelToken: conversationRequest.token
    }

    if(notAssigned !== null){
      params['not_assigned'] = notAssigned;
    }

    if(showUnreads !== null){
      params['unread_messages'] = showUnreads;
    }

    if(showArchivedMessages !== null){
      params['archived'] = showArchivedMessages;
    }
    return apiService.getResources({
        url: '/conversations/messages',
        ...params
    }).then(response => {
      const prevClientIds = conversationState.messagesByContact.map(
        (message) =>  {
          return message.client_id;
        }
      );

      const newMessages = response.data.objects.filter(
        (message) =>  {
          return !prevClientIds.includes(message.client_id);
        }
      );

      const messagesByContacts = newMessages.map((message) => formatMessage(message));

      let { page } = conversationState
      if(messagesByContacts){
        page = page + 1;
      }

      setConversationState({
        ...conversationState,
        messagesByContact: [
          ...conversationState.messagesByContact,
          ...messagesByContacts
        ],
        // conversations: [
        //   ...conversationState.conversations,
        //   ...messagesByContacts
        // ],
        page: page
      })
      setLoadingsearch(false);
      setLoading(false);

      if(showUnreads){
        setFilterUnreads(true);
      }

      return response;
    }).catch(error => {
      setLoadingsearch(false);
      setLoading(false);
    });
  };

  const getMoreConversationsByContact = (page, fromId, showUnreads, notAssigned, showArchivedMessages) => {
    setLoadingsearch(true);
    setLoading(true);

    let params = {
      page: page,
      results_per_page: num_results,
      from_id: fromId,
      cancelToken: conversationRequest.token
    }

    if(notAssigned !== null){
      params['not_assigned'] = notAssigned;
    }

    if(showUnreads !== null){
      params['unread_messages'] = showUnreads;
    }

    if(showArchivedMessages !== null){
      params['archived'] = showArchivedMessages;
    }
    return apiService.getResources({
        url: '/conversations',
        ...params
    }).then(response=> {
      const prevClientIds = conversationState.messagesByContact.map(
        (message) =>  {
          return message.client_id;
        }
      );

      const newMessages = response.data.objects.filter(
        (message) =>  {
          return !prevClientIds.includes(message.client_id);
        }
      );

      const messagesByContacts = newMessages.map((message) => formatMessage(message));

      let { page } = conversationState
      if(messagesByContacts){
        page = page + 1;
      }

      const responseObjects = response.data.objects;
      const contacts = response.map((contact) => formatMessage(contact));

      this.setConversationState({
        ...conversationState,
        // messagesByContact: [
        //   ...conversationState.messagesByContact,
        //   ...messagesByContacts
        // ],
        conversations: [
          ...conversationState.conversations,
          ...contacts
        ],
        page: page
      })
      setLoadingsearch(false);
      setLoading(false);

      if(showUnreads){
        setFilterUnreads(true);
      }

      return responseObjects;
    }).catch(error => {
      setLoadingsearch(false);
      setLoading(false);
    });
  };

  const getMessagesFiltered = (page, showUnreads, showArchivedMessages, searchQuery, searchCustomTags, selectedUser, conversationFilterRequest, clientCustomTags, currentConversationState, notAssigned, selectedGroup) => {
    setLoading(true);
    setLoadingsearch(true);

    let params = {
        page: page,
        results_per_page: num_results,
        search_query: searchQuery,
        cancelToken: conversationFilterRequest.token
    }

    if(notAssigned !== null){
      params['not_assigned'] = notAssigned;
    }

    if(showUnreads !== null){
      params['unread_messages'] = showUnreads;
    }

    if(showArchivedMessages !== null){
      params['archived'] = showArchivedMessages;
    }

    if(selectedUser !== null){
      params['selected_user'] = selectedUser;
    }

    if (selectedGroup !== null){
      params['selected_group'] = selectedGroup;
    }

    if (recentlyTransferred !== false) {
      params['recently_transferred'] = recentlyTransferred;
    }

    if (searchCustomTags !== null) {
      params['filters'] = { custom_tags: searchCustomTags };
    }

    apiService.getResources({
        url: '/conversations/messages',
        ...params
    }).then(response => {
      let filterMessages = response.data.objects['messages']

      // if (response.data.objects['conversations']){
      //   filterData = response.data.objects['conversations']
      // }

      // if (response.data.objects['messages']){
      //   filterMessages = response.data.objects['messages']
      // }

      //const filteredMessagesByContacts = filterData.map((message) => formatMessage(message));
      let chats = []
      try {
        chats = filterMessages
          .filter((message) => {
            if (!message?.client) {
              //console.log("MENSAJE SIN CLIENTE", message)
              return false;
            }
            return true;
          })
          .map((message) => formatMessageChat(message));
        chats = filterMessages.map((message) => formatMessageChat(message));
      } catch (error) {
        //console.log("ERROR: ", error)
        setLoading(false);
        setLoadingsearch(false);
      }

      const searchedConvers = searchedConversations ?
                              searchedConversations :
                              [...currentConversationState.conversations];

      setConversationState({
        ...conversationState,
        messagesByContact: [...currentConversationState.messagesByContact],
        conversations: searchedConvers,
        chats: [...chats],
      });

      setCopyManager((prevState) => ({
        ...prevState,
        searchedChats: [...chats],
      }));

      setLoading(false);
      setLoadingsearch(false);
      if(showUnreads){
        setFilterUnreads(true);
      }
    }).catch(() => {
      setLoadinglist(false);
      setLoading(false);
    });
  };

  const getConversationsFiltered = (page = 1, showUnreads, showArchivedMessages, searchQuery = null, searchCustomTags, selectedUser, conversationFilterRequest, clientCustomTags, currentConversationState, notAssigned, selectedGroup, pinup = false) => {
    setLoading(true);
    setLoadingsearch(true);

    let params = {
        page: page,
        results_per_page: num_results,
        search_query: searchQuery,
        cancelToken: conversationFilterRequest.token,
    }

    if(notAssigned !== null){
      params['not_assigned'] = notAssigned;
    }

    if(showUnreads !== null){
      params['unread_messages'] = showUnreads;
    }

    if(showArchivedMessages !== null){
      params['archived'] = showArchivedMessages;
    }

    if(selectedUser !== null){
      params['selected_user'] = selectedUser;
    }

    if (selectedGroup !== null){
      params['selected_group'] = selectedGroup;
    }

    if(searchCustomTags !== null){
      params['filters'] = {custom_tags: searchCustomTags};
    }

    params['pinup_messages'] = false;

    apiService.getResources({
        url: '/conversations',
        ...params
    }).then(response => {
      let filterData = response.data.objects

      if (response.data.objects['conversations']){
        filterData = response.data.objects['conversations']
      }

      const filteredConversationsByContacts = filterData.map((message) => formatMessage(message));

      if (pinup) {
        getConversationsByContactPipUp(page).then((response) => {
          const messagesByContactsPinup = response.data.objects.conversations.map((message) => formatMessage(message));
          setConversationState({
            ...conversationState,
            messagesByContact: messagesByContactsPinup.concat(filteredConversationsByContacts),
            conversations: messagesByContactsPinup.concat(filteredConversationsByContacts),
            chats: [],
          })
          setCopyManager((prevState) => ({
            ...prevState,
            firstConversations: {
              ...conversationState,
              messagesByContact: messagesByContactsPinup.concat(filteredConversationsByContacts),
              conversations: messagesByContactsPinup.concat(filteredConversationsByContacts),
              chats: [],
            }
          }))
          setLoadingsearch(false);
          setLoading(false);
          if(showUnreads){
            setFilterUnreads(true);
          }
        }).catch(error => {
          setLoadingsearch(false);
          setLoading(false);
        });
      } else {
        setConversationState({
          ...conversationState,
          messagesByContact: [...currentConversationState.messagesByContact],
          conversations: [...filteredConversationsByContacts],
          chats: [...currentConversationState.chats],
        });

        setCopyManager((prevState) => ({
          ...prevState,
          searchedConversations: [...filteredConversationsByContacts],
        }));

        setSearchedConversations([...filteredConversationsByContacts]);

        setLoading(false);
        setLoadingsearch(false);
        if(showUnreads){
          setFilterUnreads(true);
        }
      }

    }).catch(error => {
      setLoadinglist(false);
      setLoading(false);
    });
  };

  const getMoreMessagesFiltered = (page, fromId, showUnreads, showArchivedMessages, searchQuery, selectedUser, searchCustomTags, notAssigned, selectedGroup) => {
    setLoading(true);
    setLoadinglist(true);

    let params = {
        page: page,
        results_per_page: num_results,
        from_id: fromId,
        search_query: searchQuery,
    }

    if(notAssigned !== null){
      params['not_assigned'] = notAssigned;
    }

    if(showUnreads !== null){
      params['unread_messages'] = showUnreads;
    }

    if(showArchivedMessages !== null){
      params['archived'] = showArchivedMessages;
    }

    if(selectedUser !== null){
      params['selected_user'] = selectedUser;
    }

    if (selectedGroup !== null){
      params['selected_group'] = selectedGroup;
    }

    if(searchCustomTags !== null){
      params['filters'] = {custom_tags: searchCustomTags};
    }

    apiService.getResources({
        url: '/conversations/messages',
        ...params
    }).then(response => {
        const prevClientIds = conversationState.conversations.map(
          (message) =>  {
            return message.client_id;
          }
        );

        const newMessages = response.data.objects.filter(
          (message) =>  {
            return !prevClientIds.includes(message.client_id);
          }
        );

        const filteredMessagesByContacts = newMessages.map((message) => formatMessage(message));

        let { page } = conversationState;

        if(filteredMessagesByContacts){
          page = page + 1;
        }

        setConversationState({
          ...conversationState,
          conversations: [
            ...conversationState.messagesByContact,
            ...filteredMessagesByContacts
          ],
          page: page,
        })
        setLoading(false);
        setLoadinglist(false);
        if(showUnreads){
          setFilterUnreads(true);
        }

        return response;
    }).catch(error => {
      setLoadinglist(false);
      setLoading(false);
    });
  };

  const getMoreConversationsFiltered = (page, fromId, showUnreads, showArchivedMessages, searchQuery, selectedUser, searchCustomTags, notAssigned, selectedGroup) => {
    setLoading(true);
    setLoadinglist(true);
    let params = {
        page: page,
        results_per_page: num_results,
        from_id: fromId,
        search_query: searchQuery,
    }

    if(notAssigned !== null){
      params['not_assigned'] = notAssigned;
    }

    if(showUnreads !== null){
      params['unread_messages'] = showUnreads;
    }

    if(showArchivedMessages !== null){
      params['archived'] = showArchivedMessages;
    }

    if(selectedUser !== null){
      params['selected_user'] = selectedUser;
    }

    if (selectedGroup !== null){
      params['selected_group'] = selectedGroup
    }

    if (recentlyTransferred !== false) {
      params['recently_transferred'] = recentlyTransferred;
    }

    if(searchCustomTags !== null){
      params['filters'] = {custom_tags: searchCustomTags};
    }

    return apiService.getResources({
        url: '/conversations',
        ...params
    }).then(response => {
        const prevClientIds = conversationState.conversations.map(
          (message) =>  {
            return message.client_id;
          }
        );

        const newMessages = response.data.objects.conversations.filter(
          (message) =>  {
            return !prevClientIds.includes(message.client_id);
          }
        );

        const filteredMessagesByContacts = newMessages.map((message) => formatMessage(message));

        let { page } = conversationState;

        if(filteredMessagesByContacts){
          page = page + 1;
        }

        setConversationState({
          ...conversationState,
          conversations: [
            ...conversationState.conversations,
            ...filteredMessagesByContacts
          ],
          page: page,
        })
        setLoading(false);
        setLoadinglist(false);
        if(showUnreads){
          setFilterUnreads(true);
        }

        return response;
    }).catch(error => {
      setLoadinglist(false);
      setLoading(false);
    });
  };

  useEffect(() => {
    initialLoadRef.current = false;
    if (selectedTab != 0){
      searchStatusRef.current = true;
    }
  }, [selectedTab]);

  /**
   * Retrieves messages for a specific entry based on various filters and parameters.
   *
   * @param {Object} cancelToken - The token used to cancel the request if needed.
   * @returns {Promise} - A promise that resolves to the filtered messages.
   */
  const getConversationsForEntry = (cancelToken) => {
    return getConversationsFiltered(
      1, null, null, searchValue, selectedCustomTags, selectedUser,
      cancelToken, message_context.clientCustomTags, { ...conversationState, page: 1 },
        null, null, hasPinUp ? true : null
    )
  }

  /**
   * Fetches messages that are marked as unread.
   *
   * @param {Object} cancelToken - The token used to cancel the request if needed.
   * @returns {Promise} - A promise that resolves to the filtered messages.
   */
  const getConversationsForUnread = (cancelToken) => {
    return getConversationsFiltered(
      1, true, null, searchValue, selectedCustomTags, selectedUser,
      cancelToken, message_context.clientCustomTags, { ...conversationState, page: 1 },
        null, null, hasPinUp ? true : null
    );
  }

  /**
   * Fetches messages for contacts based on various filters.
   *
   * @param {Object} cancelToken - The token to cancel the request if needed.
   * @returns {Promise} - A promise that resolves to the filtered clients.
   */
  const getConversationsForContacts = (cancelToken) => {
    return getClientsFiltered(
      searchValue, selectedCustomTags, selectedUser, selectedGroup,
      cancelToken, { ...clientsState, page: 1 }, notAssigned
    );
  }

  /**
   * Retrieves messages that are archived based on the provided filters.
   *
   * @param {Object} cancelToken - The token used to cancel the request if needed.
   * @returns {Promise} - A promise that resolves to the filtered messages.
   */
  const getConversationsForArchived = (cancelToken) => {
    return getConversationsFiltered(
      1, null, true, searchValue, selectedCustomTags, selectedUser,
      cancelToken, message_context.clientCustomTags, { ...conversationState, page: 1 },
        null, null, hasPinUp ? true : null
    );
  }

  const cancelTokenRef = useRef(null);
  const initialLoadRef = useRef(false);
  const searchStatusRef = useRef(false);
  const debounceTimerRef = useRef(null);

  /**
   * Handles the search functionality based on the selected tab.
   * It uses debounced functions to filter messages or clients.
   */
  const handleSearch = (contacts_search = false, debounceDelay = 1800) => {

    if (selectedTab !== 2 && copyManager?.firstConversations?.conversations?.length === 0) {
      resetStateForTab();
      return;
    }

    const cancelTokenSource = axios.CancelToken.source();

    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }

    searchValueRefDebounced.current[contacts_search] = searchValue;

    debounceTimerRef.current = setTimeout(() => {
      if (searchValueRefDebounced.current[contacts_search].trim().length < searchValueMinLength) {
        return;
      }
      switch (selectedTab) {
        // Entrada
        case 0:
          if (contacts_search) {
            getConversationsFiltered(1, null, false, searchValue, selectedCustomTags, selectedUser, cancelTokenSource,
              message_context.clientCustomTags, { ...conversationState, page: 1 }, notAssigned, selectedGroup, hasPinUp ? false : null);
            return;
          } else {
            getMessagesFiltered(1, null, false, searchValue, selectedCustomTags, selectedUser, cancelTokenSource,
              message_context.clientCustomTags, { ...conversationState, page: 1 }, notAssigned, selectedGroup);
          }
          break;
        // No leidos
        case 1:
          if (contacts_search) {
            getConversationsFiltered(1, true, false, searchValue, selectedCustomTags, selectedUser, cancelTokenSource,
              message_context.clientCustomTags, { ...conversationState, page: 1 }, notAssigned, selectedGroup, hasPinUp ? false : null);
            return;
          } else {
            getMessagesFiltered(1, true, false, searchValue, selectedCustomTags, selectedUser, cancelTokenSource,
                                                  message_context.clientCustomTags, { ...conversationState, page: 1 }, notAssigned, selectedGroup);
          }
          break;
        // Contactos
        case 2:
          getClientsFiltered(searchValue, selectedCustomTags, selectedUser, selectedGroup, cancelTokenSource, { ...clientsState, page: 1 }, notAssigned);
          break;
        // Archivados
        case 3:
          if (contacts_search) {
            getConversationsFiltered(1, null, true, searchValue, selectedCustomTags, selectedUser, cancelTokenSource,
              message_context.clientCustomTags, { ...conversationState, page: 1 }, notAssigned, selectedGroup, hasPinUp ? false : null);
            return;
          } else {
            getMessagesFiltered(1, null, true, searchValue, selectedCustomTags, selectedUser, cancelTokenSource,
                                                  message_context.clientCustomTags, { ...conversationState, page: 1 }, notAssigned, selectedGroup);
          }
          break;
        default:
          if (contacts_search) {
            getConversationsFiltered(1, showUnreads, showArchivedMessages, searchValue, selectedCustomTags, selectedUser, cancelTokenSource,
              message_context.clientCustomTags, { ...conversationState, page: 1 }, notAssigned, selectedGroup, hasPinUp ? false : null);
          } else {
            getMessagesFiltered(1, showUnreads, showArchivedMessages, searchValue, selectedCustomTags, selectedUser, cancelTokenSource,
              message_context.clientCustomTags, { ...conversationState, page: 1 }, notAssigned, selectedGroup);
          }
          break;
      }
    }, debounceDelay);
  }

  /**
   * useEffect hook that initializes a copy of the conversations state when the search value is cleared
   * and there are existing conversations. This behavior allows the application to maintain the current
   * conversation state and provides a fallback for when the search input is empty, ensuring that the
   * previous conversations are available for display when the search value is less than the minimun search value
   * or less than the minimum length.
   */
  useEffect(() => {
    if (conversationState.conversations.length > 0 && copyManager?.firstConversations?.conversations?.length !== conversationState.conversations.length && searchValue.trim().length === 0) {
      setCopyManager((prevState) => ({
        ...prevState,
        firstConversations: {
          ...conversationState,
          messagesByContact: [...conversationState.messagesByContact],
          conversations: [...conversationState.conversations],
          chats: [...conversationState.chats],
        }
      })
    );
    }
  }, [conversationState.conversations, conversationState.messagesByContact, copyManager?.firstConversations]);

  /**
   * useEffect hook that handles the search functionality based on the search value, selected tab, user, custom tags, and group.
   * It sets the loading state, triggers the search or resets the state based on the search value length.
   * It also ensures that the loading state is reset when the component is unmounted or the dependencies change.
   */
  useEffect(() => {
    if (selectedTab == 2 && clientsState?.clients?.length > 0 || copyManager?.firstConversations?.conversations?.length > 0) {
      if (searchValue.trim().length >= searchValueMinLength) {
        setLoadingsearch(true);
        if (selectedTab !== 0) {
          setActiveIndex(1);
        }
        message_context.updateIsSearchingContext(!(activeIndex));
        initialLoadRef.current = false;
        searchStatusRef.current = true;
        var contact_search = true
        if (searchV2 && selectedTab == 0) {
          contact_search = activeIndex;
        }
        handleSearch(contact_search);
      } else {
        message_context.updateIsSearchingContext(false);
        resetStateForTab();
      }

      return () => {
        setLoadingsearch(false);
      }
    }
  }, [searchValue, selectedTab, selectedUser, selectedCustomTags, selectedGroup, copyManager?.firstConversations]);

  /**
   * Resets the state based on the selected tab and search value.
   * If the search value is empty, it fetches messages based on the selected tab.
   * Otherwise, it resets the conversation and client states to their initial values.
   *
   * @function resetStateForTab
   * @returns {void}
   */
  const resetStateForTab = () => {
    const cancelTokenSource = axios.CancelToken.source();

    if (searchValue.trim().length === 0) {
      if (initialLoadRef.current === false) {

        switch (selectedTab) {
          case 0:
            if (searchStatusRef.current === true) {
              getConversationsForEntry(cancelTokenSource)
              searchStatusRef.current = false;
            }
            break;
          case 1:
            getConversationsForUnread(cancelTokenSource);
            break;
          case 2:
            getConversationsForContacts(cancelTokenSource);
            break;
          case 3:
            getConversationsForArchived(cancelTokenSource);
            break;
          default:
            break;
        }
      }
      initialLoadRef.current = true;
      } else if (searchValue.trim().length < searchValueMinLength) {
        initialLoadRef.current = false;
        searchStatusRef.current = true;
        if (copyManager?.firstConversations?.conversations?.length > 0) {
          setConversationState({
            ...conversationState,
            conversations: [...copyManager.firstConversations.conversations],
            page: 1
          });
        } else {
          setConversationState({
            ...conversationState,
            conversations: [...conversationState.messagesByContact],
            page: 1
          });
        }
        setClientsState({
          ...clientsState,
          showedClients: [...clientsState.clients],
          page: 1
        });
      }
  };

  const getClients = () => {
    let url = '/recipients'
    let privateInbox = (!company_context.privateInbox || ['admin', 'supervisor'].includes(switchRole())) ? true : false;
    if (!privateInbox) {
      url = '/recipients?username=' + localStorage.getItem('username')
    }
    apiService.getResources({
        url: url,
        results_per_page: num_results,
    }).then(response => {
        const clients = response.data.objects.map((client) => {
          let newMessage = createNewMessageFromClient(client)
          return newMessage
        })
        setClientsState({
          ...clientsState,
          showedClients: clients,
          clients: clients
        })
    });
  };

  const getMoreClients = (fromId) => {
    let url = '/recipients'
    let privateInbox = (!company_context.privateInbox || ['admin', 'supervisor'].includes(switchRole())) ? true : false;
    if (!privateInbox) {
      url = '/recipients?username=' + localStorage.getItem('username')
    }

    let params = {
      url: url,
      results_per_page: num_results,
      from_id: fromId,
    }

    if(selectedUser){
      params['selected_user'] = selectedUser;
    }

    if (selectedGroup){
      params['selected_group'] = selectedGroup;
    }

    return apiService.getResources({
      ...params
    }).then(response => {
        const clients = response.data.objects.map((client) => {
          let newMessage = createNewMessageFromClient(client)
          return newMessage
        })
        setClientsState({
          ...clientsState,
          showedClients: [
            ...clientsState.showedClients,
            ...clients
          ],
          clients: [
            ...clientsState.clients,
            ...clients
          ]
        })

        return response;
    });
  };

  const getClientsFiltered = (searchQuery, searchCustomTags, selectedUser, selectedGroup, recipientFilterRequest, currentState) => {
    let url = '/recipients'
    let privateInbox = (!company_context.privateInbox || ['admin', 'supervisor'].includes(switchRole())) ? true : false;
    if (!privateInbox) {
      url = '/recipients?username=' + localStorage.getItem('username')
    }

    let params = {
        url: url,
        results_per_page: num_results,
        search_query: searchQuery,
        cancelToken: recipientFilterRequest.token
    }

    if(searchCustomTags !== null){
      params['filters'] = {custom_tags: searchCustomTags};
    }

    if(selectedUser){
      params['selected_user'] = selectedUser;
    }

    if (selectedGroup){
      params['selected_group'] = selectedGroup;
    }

    apiService.getResources({
        url: url,
        ...params
    }).then(response => {
        const filteredClients = response.data.objects.map((client) => {
          let newMessage = createNewMessageFromClient(client)
          return newMessage
        })

        setClientsState({
          ...currentState,
          showedClients: filteredClients,
        })
    }).catch(()=>{
      return;
    }). finally(() => {
      setLoadingsearch(false);
    });
  };

  const getClientsFilteredThrottled = useRef(throttle(600, getClientsFiltered));
  const getClientsFilteredDebounced = useRef(debounce(500, getClientsFiltered));

  const getMoreClientsFiltered = (searchQuery, fromId) => {

    let url = '/recipients';
    let privateInbox = (!company_context.privateInbox || ['admin', 'supervisor'].includes(switchRole())) ? true : false;
    if (!privateInbox) {
      url = '/recipients?username=' + localStorage.getItem('username')
    }

    let params = {
      url: url,
      results_per_page: num_results,
      search_query: searchQuery,
      from_id: fromId,
    }

    if(selectedUser){
      params['selected_user'] = selectedUser;
    }

    if (selectedGroup){
      params['selected_group'] = selectedGroup;
    }

    return apiService.getResources({
        ...params
    }).then(response => {
        const filteredClients = response.data.objects.map((client) => {
          let newMessage = createNewMessageFromClient(client)
          return newMessage
        })

        setClientsState({
          ...clientsState,
          showedClients: [
            ...clientsState.showedClients,
            ...filteredClients
          ]
        })

        return response;
    });
  };

  //TODO: add new client if it matches with filters
  const addNewMessageByContact = (message) => {
    message = formatDateMessage(message);
    let notifyMessage = true;
    let privateInbox = false;

    if (company_context.privateInbox){
      if (!['admin', 'supervisor'].includes(switchRole())){
        privateInbox = true;
      }
    }

    if (message.reaction){
      return null;
    }

    if (privateInbox){
      if (message?.client?.conversation?.group !== null){
        if (company_context.privateGroup){
          if (message?.client?.conversation?.user.username !== localStorage.getItem('username')){
            notifyMessage = false;
          }
        } else {
          if (message?.client?.conversation !== null){
            let userInGroup = conversationListGroupsOrigin.filter(x => x.id === message?.client?.conversation?.group?.id);
            if (userInGroup.length === 0){
              notifyMessage = false;
            }
          } else {
            notifyMessage = false;
          }
        }
      } else {
        if (message?.client?.conversation?.user?.username !== localStorage.getItem('username')){
          notifyMessage = false;
        }
      }
    }

    if (selectedCustomTags.length > 0){
      if (message.client.client_custom_tags.length > 0){
        if (!message.client.client_custom_tags.some(r => selectedCustomTags.includes(r.custom_tag.id))){
          notifyMessage = false;
        }
      }
    }

    if (notifyMessage) {
      let newMessagesByContact = [...conversationState.messagesByContact]
      let newConversations = [...conversationState.conversations]
      let conversationsListHasClient = false

      const newMessage = formatMessage(message);
      if (message?.seen === false) {
        const body = message.mime_type !== null ? nameMimetype(message.mime_type) : message.emoji;
        if (company_context.notificationAvailable){
          showNotification(message?.client?.firstname + ' ' + message?.client?.lastname,
          { body: "Nuevo Mensaje\n\n" + body, icon: `${process.env.REACT_APP_PAGE}/favicon.ico` }, message);
        }
        if (company_context.soundAvailable && localStorage.getItem('token')) sendNotificationSound();
      }

      let cleanedConversations = [...newConversations]

      let cleanedMessagesByContact = newMessagesByContact.filter((msg) => {

        if (msg.client_id !== newMessage.client_id){
            return msg
        }
      })
      cleanedMessagesByContact.unshift(newMessage)

      cleanedConversations = newConversations.filter((msg) => {
        if (msg.client_id !== newMessage.client_id){
            return msg
        }else{
          conversationsListHasClient=true
        }
      })

      if (searchValue || selectedUser) {
        if (conversationsListHasClient) {
          cleanedConversations.unshift(newMessage)
        }
      } else if (selectedGroup) {
        const isSelectedGroupMatch = (selectedGroup && message.client.conversation?.group?.id === selectedGroup) ? true : false;
        if (isSelectedGroupMatch) {
          cleanedConversations.unshift(newMessage)
        }
      } else {
        cleanedConversations.unshift(newMessage)
      }

      if (putupConversationSent && message?.type === 'sent') return

      setConversationState({
        ...conversationState,
        conversations: [...cleanedConversations],
        messagesByContact: [...cleanedMessagesByContact]
      })
    }
  }

  const assignClient = (message) => {
    const { messagesByContact, conversations } = conversationState;

    let messagesByContactIndexedByClientId = listToObject(messagesByContact, 'client_id');
    let conversationsIndexedByClientId = listToObject(conversations, 'client_id');

    const formatedMessage = formatMessage(message);

    if (!messagesByContactIndexedByClientId[formatedMessage.client_id]) {
      const position = getLessNumberIndexThan(message.id, messagesByContact.map(m => m.id));
      messagesByContact.splice(position, 0, formatedMessage);
    }

    if (!searchValue && !selectedUser && selectedCustomTags.length === 0){
      if (!conversationsIndexedByClientId[formatedMessage.client_id]) {
        const position = getLessNumberIndexThan(message.id, conversations.map(m => m.id));
        conversations.splice(position, 0, formatedMessage);
      }
    }

    setConversationState({
      ...conversationState,
      conversations: conversations,
      messagesByContact: messagesByContact
    });
  }

  const removeClient = (message) => {
    const { messagesByContact, conversations } = conversationState;

    const filteredConversations = conversations.filter((m) => m.client_id !== message.client_id);
    const filteredMessagesByContacts = messagesByContact.filter((m) => m.client_id !== message.client_id);

    setConversationState({
      ...conversationState,
      conversations: filteredConversations,
      messagesByContact: filteredMessagesByContacts,
    });
  }

  const removeMessageReaction = (message) => {
    const updatedMessagesByContact = conversationState.messagesByContact.map(messageToUpdate => {
      return messageToUpdate.id === message.id
        ? { ...messageToUpdate, body: message.parent.body, text: message.parent.body }
        : messageToUpdate;
    });

    const updatedConversations = conversationState.conversations.map(messageToUpdate => {
      return messageToUpdate.id === message.id
        ? { ...messageToUpdate, body: message.parent.body, text: message.parent.body }
        : messageToUpdate;
    });

    setConversationState({
      ...conversationState,
      messagesByContact: updatedMessagesByContact,
      conversations: updatedConversations
    });
  };

  const updateMessage = (message) => {

    let privateInbox = false;
    let privateGroup = company_context.privateGroup;

    if (company_context.privateInbox){
      if (!['admin', 'supervisor'].includes(switchRole())){
        privateInbox = true;
      }
    }

    let notifyMessage = true;

    if (privateInbox){
      if (message.client.conversation?.group !== null){
        if (privateGroup){
          if (message.client.conversation?.user.username !== localStorage.getItem('username')){
            removeClient(message);
            notifyMessage = false;
          }
        } else {
          if (message.client.conversation !== null){
            let userInGroup = conversationListGroupsOrigin.filter(x => x.id === message.client.conversation?.group?.id);
            if (userInGroup.length === 0){
              removeClient(message);
              notifyMessage = false;
            }
          } else {
            removeClient(message);
            notifyMessage = false;
          }
        }

      } else {
        if (message.client.conversation.user.username !== localStorage.getItem('username')){
          removeClient(message);
          notifyMessage = false;
        }
      }
    }

    if (!notifyMessage){
      return;
    }

    const { messagesByContact, conversations } = conversationState;

    let updatedMessagesByContact = [...messagesByContact];
    let updatedConversations = [...conversations];

    let messagesByContactIndexedByClientId = listToObject(messagesByContact, 'client_id');
    let conversationsIndexedByClientId = listToObject(conversations, 'client_id');

    updatedConversations.map((element) => element.client_id === message.client_id ? message.created = element.created : message.created = message.created);
    const formatedMessage = formatMessage(message);

    if (messagesByContactIndexedByClientId[formatedMessage.client_id]) {
      updatedMessagesByContact = messagesByContact.map((c) => (c.client_id === formatedMessage.client_id ? formatedMessage : c));
    }

    if (conversationsIndexedByClientId[formatedMessage.client_id]) {
      updatedConversations = conversations.map(c => c.client_id === formatedMessage.client_id ? formatedMessage : c);
    }
    if (!searchValue) {
      if (!conversationsIndexedByClientId[formatedMessage.client_id]) {
        const position = getLessNumberIndexThan(formatedMessage.id, conversations.map(m => m.id));
        updatedConversations.splice(position, 0, formatedMessage);
      }
    }

    const clientCustomTagIds = message.client.client_custom_tags.map(ct => ct.custom_tag.id)
    const selectedCustomTagIds = selectedCustomTags
    if (selectedCustomTags.length > 0 && message.client.client_custom_tags.length > 0 &&
        !selectedCustomTagIds.every(ct => clientCustomTagIds.includes(ct))) {
      updatedConversations = conversations;
    }

    if(selectedTab === 2){
      const currentShowedClients = showedClientsRef.current
      let updatedShowedClients = updateShowedClients(formatedMessage.client, currentShowedClients, searchValueRef.current)
      setClientsState({
        ...clientsState,
        showedClients: updatedShowedClients
      })
    }

    setConversationState({
      ...conversationState,
      conversations: updatedConversations,
      messagesByContact: updatedMessagesByContact
    })
  }

  const updateClient = (client) => {
    const client_id = client.id;

    let conversations = [...conversationState.conversations];

    let client_filtered = conversations.filter(x => x.client_id === client.id)[0];

    if (client_filtered !== undefined){
      client_filtered.name = client.firstname + ' ' + client.lastname;
    }

    let client_filtered_index = conversations.findIndex(x => x.client_id === client_id);

    if (client_filtered_index !== -1){
      conversations[client_filtered_index] = client_filtered;
    }

    setConversationState( {
        ...conversationState,
        conversations: [...conversations]
    });
  }

  const addClientCustomTags = ({client}) => {
    const client_id = client.id;

    let conversations = [...conversationState.conversations];
    let client_filtered = conversations.filter(x => x.client_id === client.id)[0];

    if (client_filtered !== undefined){
      client_filtered.client.client_custom_tags = client.client_custom_tags;
    }
    let client_filtered_index = conversations.findIndex(x => x.client_id === client_id);

    if (client_filtered_index !== -1){
      conversations[client_filtered_index] = client_filtered;
    }

    if(selectedTab === 2){
      const currentShowedClients = showedClientsRef.current
      let updatedShowedClients = updateShowedClients(client, currentShowedClients)
      setClientsState({
        ...clientsState,
        showedClients: updatedShowedClients
      })
    }

    setConversationState( {
        ...conversationState,
        conversations: [...conversations]
    });
  }

  const deleteClientCustomTag = (custom_tag) => {
    let conversations = [...conversationState.conversations];

    conversations = conversations.map(conversation => {
      conversation.client.client_custom_tags = conversation.client.client_custom_tags.filter(x => x.custom_tag.id !== custom_tag.id);
      return conversation;
    })

    let selected_tags = selectedCustomTags;

    if (selected_tags.includes(custom_tag.id)) {
      selected_tags = selected_tags.filter(x => x !== custom_tag.id);
      message_context.updateSelectedCustomTags(selected_tags);
    }

    setConversationState( {
      ...conversationState,
      conversations: conversations
    });
  }


  const customTagsCSV = ({clients}) => {
    if (clients.length > 0){
      let conversations = [...conversationState.conversations];

      clients.map(client => {
        let client_id = client.id;

        let client_info = conversations.filter(x => x.client_id === client_id)[0];
        if (client_info !== undefined){
          client_info.client.client_custom_tags = client.client_custom_tags;
        }

        let client_info_index = conversations.findIndex(x => x.client_id === client_id);

        if (client_info_index !== -1){
          conversations[client_info_index] = client_info;

          setConversationState( {
            ...conversationState,
            conversations: [...conversations]
          });
        }
        return client;
      });
    }
  }

  const addNewNote = (message) => {
    let allowed_message_types = ['note_reminder', 'scheduled_message'];

    if (!allowed_message_types.includes(message.message_type.name)){
      return null;
    }
    let notifyMessage = true;
    let privateInbox = false;

    if (company_context.privateInbox){
      if (!['admin', 'supervisor'].includes(switchRole())){
        privateInbox = true;
      }
    }
    if (privateInbox){
      if (message?.client?.conversation?.group !== null){
        if (company_context.privateGroup){
          if (message?.client?.conversation?.user.username !== localStorage.getItem('username')){
            notifyMessage = false;
          }
        } else {
          if (message?.client?.conversation !== null){
            let userInGroup = conversationListGroupsOrigin.filter(x => x.id === message?.client?.conversation?.group?.id);
            if (userInGroup.length === 0){
              notifyMessage = false;
            }
          } else {
            notifyMessage = false;
          }
        }
      } else {
        if (message?.client?.conversation?.user?.username !== localStorage.getItem('username')){
          notifyMessage = false;
        }
      }
    }

    if (notifyMessage) {
      let messageNoteKey = message.message_type.name === 'scheduled_message' ? 'scheduled_messages' : 'note_reminders';

      let message_notes_temp = [...messageNotes[messageNoteKey]]

      message_notes_temp.unshift(message);

      updateMessageNotes(prevState => {
        return {
          ...prevState,
          [messageNoteKey]: message_notes_temp
        }
      })
    }

  }

  const deleteNoteReminder = (message) => {
    if (!globalClients) {
      return null;
    }

    let allowed_message_types = ['note_reminder', 'scheduled_message'];
    if (!allowed_message_types.includes(message.message_type.name)){
      return null;
    }

    let messageNoteKey = message.message_type.name === 'scheduled_message' ? 'scheduled_messages' : 'note_reminders';
    let updated_messages = messageNotes[messageNoteKey].filter(x => x.id !== message.id);

    updateMessageNotes(prevState => {
      return {
        ...prevState,
        [messageNoteKey]: updated_messages
      }
    })

    setDialogNoteReminder(false);

    toast.current.show({severity: 'success', summary: 'Recordatorio', detail: 'Se elimino el recordatorio',
      sticky: true});
  }

  const seenNoteReminder = (message) => {
    if (!globalClients) {
      return null;
    }

    let messageNoteKey = message.message_type.name === 'scheduled_message' ? 'scheduled_messages' : 'note_reminders';
    let updated_messages = messageNotes[messageNoteKey].filter(x => x.id !== message.id);

    updateMessageNotes(prevState => {
      return {
        ...prevState,
        [messageNoteKey]: updated_messages
      }
    })

    setDialogNoteReminder(false);
    updateExpiredNoteReminders(updated_messages);
    toast.current.show({severity: 'success', summary: 'Recordatorio', detail: 'Se marcó como visto el recordatorio',
      sticky: true});
  }

  const updateStatusMessage = ({message, status}) => {
    const client_id = message.client_id;

    let conversations = [...conversationState.conversations];
    let conversation_filtered = conversations.filter(x => x.client_id === client_id)[0];

    if (conversation_filtered !== undefined){
      conversation_filtered[status] = true;
    }
    let conversation_filtered_index = conversations.findIndex(x => x.client_id === client_id);

    if (conversation_filtered_index !== -1){
      conversations[conversation_filtered_index] = conversation_filtered;
    }

    setConversationState( {
      ...conversationState,
      conversations: [...conversations]
    });
  }

  const updateExpiredNoteReminders = (notes) => {
    const dateNow = DateTime.now().toFormat('dd/MM/yyyy');

    let remindersExpired = notes.filter(x => {
      const reminderDate = DateTime.fromFormat(x.note_reminder.reminder_date, 'yyyy-MM-dd HH:mm:ss');
      return reminderDate.toFormat('dd/MM/yyyy') === dateNow;
    });

    updateExpirationNotes(remindersExpired.length);

  }

  const filterConversationList = (value) => {
    setSearchValue(value)
  }

  const filterConversationListByUser = () => {
      if (!supervisor){
        const token = localStorage.getItem('token');
        const decoded_jwt = jwtDecode(token);
        setSelectedUser(decoded_jwt.user_claims.user_id)
        setSelectedUserTmp([userClaims]);
        props.setButtonsActivated(true);
      } else {
        setShowConversationListUsers(!showConversationListUsers);
      }
  }

  const filterConversationListByTag = () => {
      setShowConversationListTags(!showConversationListTags);
  }

  const filterConversationListByGroup = () => {
      setShowConversationListGroups(!showConversationListGroups);
  }

  const userListDropDown = showConversationListUsers ? (
      <Fragment>
        <div className='list-drop'>
          <Dropdown value={selectedUserList} optionLabel="username" options={conversationListUsers}
                    onChange={(e) => setSelectedUserListHandler(e)} filter={!isMobile()} placeholder="Seleccionar Operador"/>
        </div>
      </Fragment>
  ) : null;

  const tagListDropDown = showConversationListTags ? (
    <Fragment>
      <div className='list-drop'>
        <Dropdown value={selectedTagList} optionLabel="name" options={conversationListTags}
                  onChange={(e) => setSelectedTagListHandler(e)} filter={!isMobile()} placeholder="Seleccionar Etiqueta"/>
      </div>
    </Fragment>
  ) : null;

  const groupListDropDown = showConversationListGroups ? (
      <Fragment>
        <div className='list-drop'>
          <Dropdown value={selectedGroupList} optionLabel="name" options={conversationListGroups}
                    onChange={(e) => setSelectedGroupListHandler(e)} filter={!isMobile()} placeholder="Seleccionar Grupo"/>
        </div>
      </Fragment>
  ) : null;

  const setSelectedUserListHandler = (e) => {
    setSelectedUserTmp([e.value])
    setSelectedUser(e.value.id)
    setConversationListUsers(conversationListUsersOrigin.filter((element => element.id !== e.value.id)))
    setSelectedUserList(null)
    setShowConversationListUsers(null)
  }

  const setSelectedTagListHandler = (e) => {
    setSelectedTagTmp([...selectedTagTmp, e.value])
    setSelectedTag(e.value.id)

    setConversationListTags(conversationListTags.filter((element => element.id !== e.value.id)))
    setSelectedTagList(null);
    setShowConversationListTags(null);

  }

  const setSelectedGroupListHandler = (e) => {
    setSelectedGroupTmp([e.value]);
    setSelectedGroup(e.value.id);
    setConversationListGroups(conversationListGroupsOrigin.filter((element => element.id !== e.value.id)));
    setSelectedGroupList(null);
    setShowConversationListGroups(null);
  }

  const userFilter = selectedUserTmp.map((user, index) => {
    let add_title = user.name;

    return (
        <Fragment key={index}>
            <span className="custom-tags uno" data-tip={add_title}
              style={{
                  marginRight: '0.1em',
                  marginLeft: '0.4em',
                  marginBottom: '0.1em'
              }}><FontAwesomeIcon icon={'user'}></FontAwesomeIcon>{user?.username ? ' ' + user.username + ' ' : ' Tú '}<i onClick={()=>onUserFilter(user)} style={{fontSize: '0.8rem', cursor: 'pointer'}} className="pi pi-times"></i>
            </span>
        </Fragment>
    );
  });

  const assignedUserFilter = (
    selectedTab === 4 && (
      <Fragment>
        <span className="custom-tags uno"
            style={{
            marginRight: '0.1em',
            marginLeft: '0.4em',
            marginBottom: '0.1em'
          }}><FontAwesomeIcon icon={'user'}></FontAwesomeIcon> asignado <i onClick={()=>setSelectedTab(0)} style={{fontSize: '0.8rem', cursor: 'pointer'}} className="pi pi-times"></i>
        </span>
      </Fragment>
    )
  );

  const notAssignedUserFilter = (
    selectedTab === 5 && (
      <Fragment>
        <span className="custom-tags uno"
            style={{
            marginRight: '0.1em',
            marginLeft: '0.4em',
            marginBottom: '0.1em'
          }}><FontAwesomeIcon icon={'user'}></FontAwesomeIcon> no asignado <i onClick={()=>setSelectedTab(0)} style={{fontSize: '0.8rem', cursor: 'pointer'}} className="pi pi-times"></i>
        </span>
      </Fragment>
    )
  );

  const recentlyTransferredUserFilter = (
    selectedTab === 6 && (
      <Fragment>
        <span className="custom-tags uno"
          style={{
            marginRight: '0.1em',
            marginLeft: '0.4em',
            marginBottom: '0.1em'
          }}><FontAwesomeIcon icon={'share'}></FontAwesomeIcon> recientemente derivado <i onClick={() => setSelectedTab(0)} style={{ fontSize: '0.8rem', cursor: 'pointer' }} className="pi pi-times"></i>
        </span>
      </Fragment>
    )
  );

  const tagFilter = selectedTagTmp.map((tag, index) => {
    const onTagFilter = (tag) => {
      setSelectedTagTmp(selectedTagTmp.filter((element => element.id !== tag.id)));
      setConversationListTags([...conversationListTags, tag]);
      setSelectedTag(null);
    }

    return (
        <Fragment key={index}>
            <span className="custom-tags dos"
              style={{
                  marginRight: '0.1em',
                  marginLeft: '0.4em',
                  marginBottom: '0.1em',
                  backgroundColor: tag.color
              }}>
              <FontAwesomeIcon icon={'fa-regular fa-tag'}></FontAwesomeIcon>{' ' + tag.name + ' '}
              <i onClick={()=>onTagFilter(tag)} style={{fontSize: '0.8rem', cursor: 'pointer'}}
                 className="pi pi-times"></i>
            </span>
        </Fragment>
    );
  });

  const groupFilter = selectedGroupTmp.map((group, index) => {
    let add_title = group.name;

    return (
        <Fragment key={index}>
            <span className="custom-tags uno" data-tip={add_title}
              style={{
                  marginRight: '0.1em',
                  marginLeft: '0.4em',
                  marginBottom: '0.1em'
              }}>
              <FontAwesomeIcon icon={'user-group'}></FontAwesomeIcon>{' ' + group.name + ' '}
              <i onClick={()=>onGroupFilter(group)}
                 style={{fontSize: '0.8rem', cursor: 'pointer'}} className="pi pi-times">
              </i>
            </span>
        </Fragment>
    );
  });

  const onTagFilter = (tag) => {
    setSelectedTagTmp(selectedTagTmp.filter((element => element.id !== tag.id)))
    setConversationListTags([...conversationListTags, tag])
    setSelectedTag(null)
  }

  const onUserFilter = (user) => {
    setSelectedUserTmp(selectedUserTmp.filter((element => element.id !== user.id)))
    setConversationListUsers([...conversationListUsers, user])
    setSelectedUser(null)
  }

  const onGroupFilter = (group) => {
    setSelectedGroupTmp(selectedGroupTmp.filter((element => element.id !== group.id)))
    setConversationListGroups([...conversationListGroups, group]);
    setSelectedGroup(null)
  }

  const getUsers = () => {
    apiService.getResources({
      url: '/conversations/users',
    }).then(response => {
      let response_data = response.data.objects;
      const token = localStorage.getItem('token');
      const decoded_jwt = jwtDecode(token);
      setUserClaims(...response_data.filter((element => element.id === decoded_jwt.user_claims.user_id)));
      setConversationListUsers(response_data);
      setConversationListUsersOrigin(response_data);
    });
  };

  const getDialogParams = (action, id) => {
    let data = {};

    switch (action){
      case 'list':
        data['width'] = {'width': 'auto', 'min-width': '30vw'};
        data['height'] = {'height': 'auto', 'min-height': '20vw'};
        data['title'] = 'Etiquetas Guardadas';
        data['url'] = 'URL';
        data['method'] = 'post';
        data['fields'] = [{
          name: 'custom_tags',
          elementType: 'customTags',
          elementConfig: {},
          elementClass: 'col-12 md-12',
          value: '',
        }];
        break;
      case 'new':
        data['width'] = {'width': 'auto', 'min-width': '30vw'};
        data['height'] = {'height': 'auto', 'min-height': '20vw'};
        data['title'] = 'Crear Etiqueta';
        data['url'] = '/custom_tags';
        data['method'] = 'post';
        data['fields'] = [{
          name: 'name',
          elementType: 'text',
          elementConfig: {
            traduction: 'Titulo'
          },
          elementClass: 'col-12 md-12 mt-1',
          value: '',
        },
        {
          name: 'color',
          elementType: 'color',
          elementConfig: {
            traduction: 'Color'
          },
          elementClass: 'col-12 md-12 mt-1',
          value: '',
        },
        {
          name: 'custom_tag_list',
          elementType: 'customTagList',
          elementConfig: {
            traduction: 'Titulo'
          },
          dependence_of: ['name'],
          elementClass: 'col-12 md-12 mt-1',
        }];
        break;
      case 'reassign_messages':
        data['width'] = {'width': 'auto', 'min-width': '30vw'};
        data['height'] = {'height': 'auto', 'min-height': '20vw'};
        data['title'] = 'Transferir Chats';
        data['url'] = 'URL';
        data['method'] = 'post';
        data['fields'] = [{
          name: 'reassign_conversations',
          elementType: 'reassignMessages',
          elementConfig: {
            traduction: 'Titulo',
            value: ''
          },
          elementClass: 'col-12 md-12 mt-1',
          value: {
            assign_from: null,
            assign_to: null
          }
        }];
        break
      default:
        break;
    }
    return data;
  }

  const getHeaderIcons = (action, close_event) => {
    let add_new_tag = null;

    add_new_tag = action === 'list' && (
        <Button
          className="ml-auto"
          label="Nuevo"
          icon='pi pi-plus'
          onClick={() => dialogCustomTags('new', null)}
        />
    );

    const close_dialog = (
      <button type="button" onClick={(e) => close_event(false)} class="p-dialog-header-icon p-dialog-header-close p-link" aria-label="Close">
        <span class="p-dialog-header-close-icon pi pi-times" aria-hidden="true"></span>
      </button>
    );

    return (
        <div className="flex">
          {add_new_tag}
          {close_dialog}
        </div>
    );
  }

  const getHeaderDialog = (title, action) => {
    const get_back = action === 'new' && (
      <span className="custom-tag-get-back" onClick={(e) => dialogCustomTags('list', null)}>
        <FontAwesomeIcon icon={'arrow-left'}/>
      </span>
    );

    return (
      <div className="p-dialog-title">
        <span>
          {get_back}
          <b>{title}</b>
        </span>
      </div>
    );

  }

  const getDisplayFooterDialog = (action) => {
    let show_footer = false;
    if (action === 'new') {
      show_footer = true
    }
    return show_footer
  }

  const dialogCustomTags = (action, id) => {
    setDialogCustomTag(true);
    const params = getDialogParams(action, id);
    let value_dialog = {
      'url': params.url,
      'method': params.method,
      'header': getHeaderDialog(params.title, action),
      'icons': getHeaderIcons(action, setDialogCustomTag),
      'show_footer': getDisplayFooterDialog(action),
      'submit_label': 'Crear',
      'sumaryToast': 'Etiquetas',
      'fields': params.fields,
      'width': params.width,
      'height': params.height,
      'buttons_rounded': true
    }
    if (action === 'new'){
      value_dialog['detailToast'] = 'Etiqueta guardada';
    } else if (action === 'list') {
      value_dialog['detailToast'] = 'Etiqueta modificada';
    }

    setUpdateModalInfo(true);
    setValueDialog(value_dialog);

  }

  const dialogReassignConversation = (action, id) => {
    message_context.updateDialogReassignMessages(true);
    setDialogCustomTag(false);
    const params = getDialogParams(action, id);
    let value_dialog = {
      'url': params.url,
      'method': params.method,
      'header': getHeaderDialog(params.title, action),
      'icons': getHeaderIcons(action, message_context.updateDialogReassignMessages),
      'show_footer': false,
      'submit_label': 'Crear',
      'sumaryToast': 'Transferir Chats',
      'fields': params.fields,
      'width': params.width,
      'height': params.height,
      'buttons_rounded': true,
      'detailToast': 'Se transfirieron los Chats'
    }

    setUpdateModalInfo(true);
    setValueDialog(value_dialog);
  }

  const onChangeFilterHandler = (event) => {
    filterConversationList(event.target.value);
  }

  const onClearValue = () => {
    filterConversationList('');
  }

  const handleScroll = () => {
    if (!paginating && !loading && conversationState.conversations.length > 0) {
      const lastMessage = conversationState.conversations[conversationState.conversations.length - 1]
      const lastClient = clientsState.showedClients[clientsState.showedClients.length - 1]
      if (selectedUser || searchValue || selectedCustomTags !== null) {
        if ((selectedTab !== 2) && !lastMessageReached) {
          setPaginating(true);
          getMoreConversationsFiltered(conversationState.page + 1, lastMessage.id, showUnreads, showArchivedMessages, searchValue, selectedUser, selectedCustomTags, notAssigned, selectedGroup)
              .then(result => {
                setPaginating(false);
                if (result.data.objects.length === 0 || result.data.num_results < num_results) {
                  setLastMessageReached(true);
                }
              });
        }
        if (selectedTab === 2 && !lastClientReached) {
          setPaginating(true);
          getMoreClientsFiltered(searchValue, lastClient.client_id)
              .then(result => {
                if (result.data.objects.length === 0 || result.data.num_results < num_results) {
                  setLastClientReached(true);
                }
                setPaginating(false);
              });
        }
      } else {
        if ((selectedTab !== 2) && !lastMessageReached) {
          setPaginating(true);
          getMoreConversationsByContact(conversationState.page + 1, lastMessage.id, showUnreads, showArchivedMessages, notAssigned)
              .then(result => {
                if (result.data.objects.length === 0 || result.data.num_results < num_results) {
                  setLastMessageReached(true);
                }
                setPaginating(false);
              });
        }
        if (selectedTab === 2 && !lastClientReached) {
          setPaginating(true);
          getMoreClients(lastClient.client_id)
              .then(result => {
                if (result.data.objects.length === 0 || result.data.num_results < num_results) {
                  setLastClientReached(true);
                }
                setPaginating(false);
              });
        }
      }
    }
  }

  let itemsToShow = (selectedTab !== 2) ? conversationState.conversations : clientsState.showedClients;

  const chatsToShow = useMemo(() => {
    if (searchValue !== ''){
      return conversationState?.chats != null ? conversationState.chats : []
    } else {
      return [];
    }
  }, [conversationState?.chats, searchValue]);


  if (selectedTab !== 2) {
    itemsToShow = itemsToShow.filter((c) => {
      return filterArchivedMessages ? c.client.archived : !c.client.archived;
    });

    if (filterUnreads) {
      itemsToShow = itemsToShow.filter(c => c.client.unread_messages || c.client.id === conversationActive);
    }
  }

  function getFilteredConversationsLocal(conversations, { assign, notAssigned, recentlyTransferred, selectedGroup, selectedUser }) {
    let filteredConversations = conversations.filter(conversation => {
      const userId = jwtDecode(localStorage.getItem('token')).user_claims.user_id
      const hasUsername = conversation.client.conversation?.user?.username !== undefined;
      const groupIdMatch = selectedGroup && conversation.client.conversation?.group?.id === selectedGroup;
      const userIdMatch = selectedUser && conversation.client.conversation?.user?.id === selectedUser;
      const bothGroupAndUserSelected = selectedGroup && selectedUser;
      const groupAndUserMatch = bothGroupAndUserSelected ? (groupIdMatch && userIdMatch) : (groupIdMatch || userIdMatch);
      const isRecentlyTransferred = conversation?.client?.conversation?.recently_transferred && conversation?.client?.conversation?.user?.id === userId;
      const shouldShowConversation = (!assign && !notAssigned && !selectedGroup && !selectedUser && !recentlyTransferred) ||
        (assign && hasUsername) ||
        (notAssigned && !hasUsername) ||
        (recentlyTransferred && isRecentlyTransferred) ||
        groupAndUserMatch;

      return shouldShowConversation;
    });
    if (Array.isArray(selectedCustomTags) && selectedCustomTags.length > 0) {
      filteredConversations = filteredConversations.filter(conversation => {
        const clientCustomTagIds = conversation.client.client_custom_tags.map(ct => ct.custom_tag.id);
        return selectedCustomTags.every(tag => clientCustomTagIds.includes(tag));
      });
    }

    return filteredConversations;
  }

  itemsToShow = getFilteredConversationsLocal(itemsToShow, {
    assign,
    notAssigned,
    recentlyTransferred,
    selectedGroup,
    selectedUser
  });

  itemsToShow.map(conversation => {
    if (conversation.client.client_custom_tags === undefined){
      console.warn('Client custom tags are undefined: ', conversation.client);
      conversation.client.client_custom_tags = [];
    }
    let client_custom_tags = conversation.client.client_custom_tags.map(tag => {

      if (message_context.customTags.filter(x => x.id === tag.custom_tag.id)[0] !== undefined){
        let client_tag = {};
        client_tag['id'] = tag.custom_tag.id;
        client_tag['name'] = message_context.customTags.filter(x => x.id === tag.custom_tag.id)[0].name;
        client_tag['color'] = message_context.customTags.filter(x => x.id === tag.custom_tag.id)[0].color;
        client_tag['fixed'] = message_context.customTags.filter(x => x.id === tag.custom_tag.id)[0].fixed;

        return client_tag
      }

    });
    client_custom_tags = client_custom_tags.filter(x => x !== undefined);
    conversation.client.tags = client_custom_tags
    return conversation

  });

  function orderByPinup(a, b) {
    if (a.client.pinup && !b.client.pinup) return -1;
    else if (!a.client.pinup && b.client.pinup) return 1;
    else return 0;
  }


  if (hasPinUp) itemsToShow.sort(orderByPinup);

  const conversationItems = (
    <div>
      {chatsToShow.length > 0 ? <div className="mt-3 mb-3" style={{textAlign: 'center', fontSize: 20}}> Conversaciones </div> : null}
      {
        itemsToShow.map((conversation) => {
          if (conversation !== undefined) {
            let active = false;
            if (conversation.client.id === conversationActive) {
              active = true;
            }
            return (<ConversationListItem key={
              `${selectedTab}${((selectedTab !== 2) && '-' + showArchivedMessages)}-${conversation.client_id}`
            }
            supervisor={supervisor}
            data={conversation}
            selectedTab={selectedTab}
            onClick={() => conversationListItemHandler(conversation)}
            conversationActive={active}
            />)
          }
        })}
    </div>
  );

  const chatItems = chatsToShow.length > 0 ? (
      <div className='chatItemsDiv'>
        {
          chatsToShow.map((chat) => {
            let active = false;
            active = (chat.client.id === conversationActive && searchValue.trim().length < searchValueMinLength) ||
            (searchValue.trim().length >= searchValueMinLength && chat.id === chatActive);
            return (
                <ChatListItem key={`chat-list-item-${selectedTab}${((selectedTab !== 2) && '-' + showArchivedMessages)}-${chat.id}`}
                              data={chat} selectedTab={selectedTab}
                              onClick={() => conversationListItemHandler(chat)}
                              conversationActive={active}
                              searchValue={searchValue}/>
            )
          })}

      </div>
  ) : null;

  let viewCreateUser = (
    <Dialog header={'Creación de Cliente'} style={{ width: '94vh', height: 'auto' }} visible={showClientView} onHide={() => setShowClientView(false)}>
        <Fragment>
          <ClientFormCustom client={null} contacts={[]}/>
        </Fragment>
      </Dialog>
  );

  const conversationListItemHandler = (conversation) => {
    props.onSelectClientHandler(conversation.client);
    setConversationActive(conversation.client.id);
    setChatActive(conversation.id);
    if (conversation.client.id !== conversationActive){
      if (player.ref !== null && player.ref !== undefined && !audioEnded && !isMobile()){
        player.hidden = false;
        setIsAudioVisible(true)
        player.play();
      }
    }
  }

  const handleAudioHide = () => {
    setAudioEnded(true)
    player.hidden = true;
    setIsAudioVisible(false)
    player.pause();
  }

  let tabOptions = ['fa-regular fa-inbox', 'fa-regular fa-envelope', 'fa-regular fa-address-book', 'fa-regular fa-box-archive'];

  useEffect(() => {
    switch (selectedTab) {
      case 0:
        setArchivedMessages(false);
        setFilterArchivedMessages(false);
        setShowUnreads(null);
        setAssign(null)
        setNotAssigned(null)
        setRecentlyTransferred(null);
        break
      case 1:
        setArchivedMessages(false);
        setFilterArchivedMessages(false);
        setShowUnreads(true);
        setAssign(null)
        setNotAssigned(null)
        setRecentlyTransferred(null);
        break
      case 3:
        setArchivedMessages(true);
        setFilterArchivedMessages(true);
        setShowUnreads(null);
        setAssign(null)
        setNotAssigned(null)
        setRecentlyTransferred(null);
        break
      case 4:
        setArchivedMessages(false);
        setFilterArchivedMessages(false);
        setShowUnreads(null);
        setAssign(true)
        setNotAssigned(null)
        setRecentlyTransferred(null);
        break
      case 5:
        setArchivedMessages(false);
        setFilterArchivedMessages(false);
        setShowUnreads(null);
        setAssign(null)
        setNotAssigned(true)
        setRecentlyTransferred(null);
        break
      case 6:
        setArchivedMessages(false);
        setFilterArchivedMessages(false);
        setShowUnreads(null);
        setAssign(null)
        setNotAssigned(null)
        setRecentlyTransferred(true);
      default:
        break;
    }
  }, [(selectedTab)]);


  const getChatFromClientID = (client_id) => {
    let chat = conversationState.conversations.filter(x => x.client.id === client_id);
    return chat[0];
  }

  let render_modal = dialogCustomTag || message_context.dialogReassignMessages ? modalInfo : null;

  let modal_message_note_reminders = dialogNoteReminder ? <MessageNoteReminder enabled={dialogNoteReminder}
                                                                               setEnabled={setDialogNoteReminder}
                                                                               isGlobal={true}/> : null

  let render_note_messages = selectedMessageNote !== null && globalClients ? <MessagesNoteModal
        selectedMessage={selectedMessageNote} selectedClient={selectedClient} contactSelected={selectedContact}
        activeNotes={activeMessageNoteModal} setActiveNotesEnabled={setActiveMessageNoteModal} setOriginalModalEnabled={setDialogNoteReminder}
        clientFullName={clientFullName} setClientOnFullChat={conversationListItemHandler} chat={getChatFromClientID(selectedClient)}
    /> : null;

  const rightToolBarItems = () => {
    let reminder_counter = expirationNotes > 0 ? (
      <span className="fa-layers-counter fa-layers-bottom-right">{expirationNotes}</span>) : null;

    let reminderShakeProp = expirationNotes > 0 ? {'shake': true} : {'shake': false};

    let reminderShakePropMore = !itemMenuMore ? expirationNotes > 0 ? {'shake': true} : {'shake': false} : null;
    let width = "1.8em";
    let height = "1.8em";

    let more_items = (
        <ToolbarButton key="more_items"
                       icon={"fa-regular fa-ellipsis-vertical"}
                       style={{width: width, height: height}}
                       onClick={() => itemMenuMore ? setItemMenuMore(false) : setItemMenuMore(true)}
                       {...reminderShakePropMore}>
            {!itemMenuMore ? <div style={{position: 'relative', right: '10px'}}>{reminder_counter}</div> : null}
            {itemMenuMore ? <FontAwesomeIcon icon={'times'} size={'1x'} transform={'shrink-1 up-12 right-16'} /> : null}
        </ToolbarButton>
    );

    let add_custom_tags = (
        <ToolbarButton key="filter_custom_tag"
                       name={"Etiquetas"}
                       icon={"fa-regular fa-tag"}
                       style={{width: width, height: height}}
                       onClick={() => dialogCustomTags('list', null)}>
        </ToolbarButton>
    );

    let add_user = (
      <ToolbarButton key="create_user"
                     name={"Usuario"}
                     icon={"fa-regular fa-user"}
                     style={{width: width, height: height}}
                     onClick={() => setShowClientView(true)}>
      </ToolbarButton>
    );

    let transfer = ['admin', 'supervisor', 'audience'].includes(switchRole()) ? [
        <ToolbarButton key="transfer"
                       name={"Transferir Chats"}
                       icon={"fa-regular fa-exchange-alt"}
                       style={{width: width, height: height}}
                       onClick={() => dialogReassignConversation('reassign_messages', null)}/>
    ] : [];

    let reminders = (
        <ToolbarButton key="reminders"
                       name="Recordatorios"
                       icon={"fa-regular fa-calendar-check"}
                       size={"xs"}
                       style={{width: width, height: height}}
                       onClick={() => showMessageNoteReminder()}
                       {...reminderShakeProp}>
            <div style={{position: 'relative', right: '5px'}}>{reminder_counter}</div>
        </ToolbarButton>
      );

    let put_up_conversation_sent = (
      <ToolbarButton key="reminders"
                     name="Control Scroll"
                     icon={"fa-regular fa-bars-staggered"}
                     size={"xs"}
                     style={{width: width, height: height}}
                     onClick={() => setPutupConversationSent(!putupConversationSent)}>
                     {!putupConversationSent ? <FontAwesomeIcon icon={'fa-solid fa-arrow-up-long'} size={'1x'} style={{width: '1.5em', height: '1.2em'}} transform={'shrink-1 left-16'} /> : null}
        </ToolbarButton>
      );

    if (itemMenuMore) return [more_items, transfer, reminders, add_custom_tags, add_user, put_up_conversation_sent]
    else return [more_items]

  };

  const showMessageNoteReminder = () => {
    setDialogNoteReminder(true);
  }

  const searchConversationContactHandler = (e) => {
    setLoadingSearchConversationContact(true)
    const CancelTokenFilterRequest = axios.CancelToken;
    const conversationFilterRequest = CancelTokenFilterRequest.source();
    getClientsFilteredDebounced.current(searchConversationContactValue.phone_number, selectedCustomTags, selectedUser, selectedGroup,
        conversationFilterRequest, {...clientsState, page: 1}, notAssigned);
  }

  const handleTabChange = (e) => {
    setActiveIndex(e.index);
    if (searchValue !== '' && searchValue.trim().length >= searchValueMinLength) {
      if (e.index == 1 && searchValueRefDebounced.current[1] !== searchValue || e.index == 0 && searchValueRefDebounced.current[0] !== searchValue)
      handleSearch(e.index, 50)
    }
    message_context.updateIsSearchingContext(!(e.index));
  };

  const conversationScroll = (
      <InfiniteScroll
        dataLength={itemsToShow.length}
        pageStart={0}
        initialLoad={false}
        loadMore={handleScroll}
        hasMore={true}
        useWindow={false}
        getScrollParent={() => props.parentRef.current}
        loader={loadinglist ? <ConversationSkeleton count={5}/> : null}
      >
        {itemsToShow.length === 0 ? <ResultNotFount/> : conversationItems}
      </InfiniteScroll>
  )

  const messagesScroll = (
    <InfiniteScroll
      dataLength={itemsToShow.length}
      pageStart={0}
      initialLoad={false}
      //loadMore={handleScroll}
      //hasMore={true}
      useWindow={false}
      //getScrollParent={() => props.parentRef.current}
      loader={loadinglist ? <ConversationSkeleton count={5}/> : null}
    >
      {chatsToShow.length === 0 ? <MessageNotFount/> : chatItems}
    </InfiniteScroll>
  )

  const itemConversationTemplate = (conversation, options) => {
    if (!conversation) return null;

    const isActive = conversation.client?.id === conversationActive;

    switch (conversation) {
      case 'first':
        return allTagFilter ? allTagFilter : conversationQuick
      case 'loading':
        return <ConversationSkeleton count={5} />;
      case 'noMoreResult':
        return <ResultNotMoreFount/>
      case 'moreLoading':
        return <LoadMoreResults loadingResult={handleScroll}/>
      case 'resultNotFount':
        return <ResultNotFount/>
      default:
        return (
          <div>
            <ConversationListItem
              key={`${selectedTab}-${selectedTab !== 2 ? showArchivedMessages : ''}-${
                conversation.client_id
              }`}
              supervisor={supervisor}
              data={conversation}
              selectedTab={selectedTab}
              onClick={() => conversationListItemHandler(conversation)}
              conversationActive={isActive}
            />
          </div>
        );
    }
  };

  const onHandleConversationVirtualScroll = (event) => {
    const container = event.target;
    const scrollPosition = Math.round(container.scrollHeight - container.scrollTop);
    const clientHeight = Math.round(container.clientHeight);
    if (Math.abs(scrollPosition - clientHeight) <= 1) handleScroll();
  };

  let itemConversation = !loadingsearch ? loadinglist ? ['first', ...itemsToShow, 'loading'] : lastMessageReached  ? ['first', ...itemsToShow, 'noMoreResult'] : !loadingsearch ? loading ? ['first', 'loading'] : itemsToShow.length === 0 ? ['first', ...itemsToShow, 'resultNotFount'] : ['first', ...itemsToShow, 'moreLoading'] : ['first', 'loading'] : ['first', 'loading'];

  const conversationVirtualScroll = (
      <VirtualScroller
        items={itemConversation}
        onScroll={onHandleConversationVirtualScroll}
        itemSize={127}
        itemTemplate={itemConversationTemplate}
        showSpacer={true}
      />
  )

  const conversationQuick = (
    selectedTab === 2 ? (
      <div style={{width: isMobile() ? height - 20 : 'auto'}} className={'init-conversation-main'}>
        <p className={'toolbar-title mt-3'} style={{textAlign: 'center'}}>Inicio de conversación rápida</p>
        <div className='init-conversation'>
          <ConversationContact
              placeholder={'Iniciar Conversacion'}
              onChange={(value) => setSearchConversationContactValue(value)}
              value={searchConversationContactValue}/>
          <span data-tip={'Iniciar Conversación Rápida'}>
            <FontAwesomeIcon onClick={(e) => searchConversationContactHandler(e)}
                             icon={"fa-regular fa-paper-plane"} className={`init-conversation-icon btn-actions-default init-conversation-icon-${searchConversationContactValue.phone_valid}`}/>
          </span>
        </div>
        {searchConversationContactValue.phone_number !== '' ? (!searchConversationContactValue.phone_valid ? (
          <div className={'conversation-contact-phone-invalid'}>
              Numero inválido
          </div>
          ) : null) : null }
      </div>
      ) : null
  )

  const allTagFilter = (
    (assignedUserFilter || notAssignedUserFilter || recentlyTransferredUserFilter || userFilter.length > 0 || tagFilter.length > 0 || groupFilter.length > 0) && (
      <div className={`user-tag-filter mt-auto mb-4`}>
        {assignedUserFilter}
        {notAssignedUserFilter}
        {recentlyTransferredUserFilter}
        {userFilter}
        {tagFilter}
        {groupFilter}
      </div>
    )
  );

const conversationView = (
  searchV2 && searchValue !== '' && searchValue.trim().length >= searchValueMinLength && selectedTab !== 2 ? (
    <div className='tabs-container' ref={props.parentRef}>
      <TabView activeIndex={activeIndex} onTabChange={handleTabChange}>
      {selectedTab == 0 && (
          <TabPanel header="Todos los mensajes" leftIcon="fa-regular fa-comments search-find-icon mr-2">
            <div className="tab-scrollable" ref={props.parentRef}>
              <div className="conversation-list">
                {!loadingsearch ? messagesScroll : <ConversationSkeleton count={10}/>}
              </div>
            </div>
          </TabPanel>
        )}
        <TabPanel header="Chats" leftIcon="fa-regular fa-user search-find-icon mr-2">
          <div className="tab-scrollable" ref={props.parentRef}>
            <div className="conversation-list">
              {conversationVirtualScroll}
            </div>
          </div>
        </TabPanel>
      </TabView>
    </div>
  ) : (
    <div className="scrollable" ref={props.parentRef}>
      <div className="conversation-list">
        {conversationVirtualScroll}
      </div>
    </div>
  )
);

const conversationViewMobile = (
  searchV2 && searchValue !== '' && searchValue.trim().length >= searchValueMinLength && selectedTab !== 2 ? (
      <TabView activeIndex={activeIndex} onTabChange={handleTabChange}>
        {selectedTab == 0 && (
          <TabPanel header="Todos los mensajes" leftIcon="fa-regular fa-comments search-find-icon mr-2">
              <div className="conversation-list" style={{width: isMobile() ? height - 20 : 'auto'}}>
                {!loadingsearch ? messagesScroll : <ConversationSkeleton count={10}/>}
              </div>
          </TabPanel>
        )}
        <TabPanel header="Chats" leftIcon="fa-regular fa-user search-find-icon mr-2">
            <div className="conversation-list" style={{width: isMobile() ? height - 20 : 'auto'}}>
              {!loadingsearch ? conversationScroll : <ConversationSkeleton count={10}/>}
            </div>
        </TabPanel>
      </TabView>
  ) : (
    <div className='conversation-list' style={{width: isMobile() ? height - 20 : 'auto'}}>
      {!loadingsearch ? conversationScroll : <ConversationSkeleton count={5}/>}
    </div>
  )
);

const toolbar = (
  <div>
    <Toolbar
        rightItems={rightToolBarItems()}
        hideShowLeftItems={false}
        leftItems={<ChannelSelector/>}
        className={"toolbar toolbar-custom"}>
    </Toolbar>
    <CustomTabHeaders fontAwesomeIcon={true} headers={tabOptions} selectedIndex={selectedTab} onChange={setSelectedTab}/>
  </div>
);

  const searchInput = (
    <div className='search-filter'>
      {showConversationListGroups || showConversationListUsers || showConversationListTags ? null : <ConversationSearch className='conversation-search' onClearValue={onClearValue} onChange={onChangeFilterHandler} value={searchValue} loading={loadingsearch} />}
      {userListDropDown}
      {tagListDropDown}
      {groupListDropDown}
      <div data-tip={!isMobile() ? 'Filtro' : null} >
        <FontAwesomeIcon
          onClick={(event) => {
            if (!(selectedTab == 0 && message_context.isSearchingContext && activeIndex == 0)) {
              showConversationListTags ? setShowConversationListTags(null) :
              showConversationListUsers ? setShowConversationListUsers(null) :
              showConversationListGroups ? setShowConversationListGroups(null) :
              refMenu.current.toggle(event);
            }
          }}
          icon={(showConversationListTags || showConversationListUsers || showConversationListGroups) ? "times" : "fa-regular fa-filter"}
          className={"btn-actions btn-actions-default btn-fontawesome"}
          style={{
            cursor: selectedTab == 0 && message_context.isSearchingContext && activeIndex == 0 ? 'default' : 'pointer',
            color: selectedTab == 0 && message_context.isSearchingContext && activeIndex == 0 ? '#aaa' : 'var(--primary-color)',
            pointerEvents: selectedTab == 0 && message_context.isSearchingContext && activeIndex == 0 ? 'none' : 'auto',
            width: '1.5em',
            height: '1.4em'
            }}/>
      </div>
    </div>
  );

const audioCurrent = (
  <div className={isAudioVisible ? "audio-player-visible" : "audio-player-hidden"}>
      {isAudioVisible ? (
        <div className="audio-info-container">
          <button onClick={() => handleAudioHide()}
            type="button"
            class="p-dialog-header-icon p-dialog-header-close-audio p-link"
            aria-label="Close">
              <span class="p-dialog-header-close-icon pi pi-times"/>
          </button>
          <Avatar
                  maxInitials={1}
                  name={player ? player.name : 'Cliente'}
                  round={true}
                  size="60"/>
          <div className="conversation-title">
            <h1 className="conversation-list-item-name">
                {player ? player.name : 'Cliente'}
            </h1>
          </div>
        </div>
      ) : null}
      <audio id="globalAudioPlayer" className='audio-info-container' controls hidden controlsList='nodownload noplaybackrate'/>
  </div>
);

const menuFilter = (
  <div>
    <Menu className='menu-filter' model={itemMenuFilter} popup ref={refMenu}/>
  </div>
);

const conversationUp = (
  <div style={{width: isMobile() ? height - 20 : 'auto'}}>
    {toolbar}
    {searchInput}
    {menuFilter}
  </div>
)
  return (
    <div className='messenger-conversation-list-container'>
      {conversationUp}
      {isMobile() && (allTagFilter ? allTagFilter : conversationQuick)}
      <div className='content'>
        {audioCurrent}
        <Toast ref={toast}/>
        {isMobile() ? conversationViewMobile : conversationView}
        {render_modal}
        {modal_message_note_reminders}
        {render_note_messages}
        {loadingConversation ? <CustomSpinner status={'Obteniendo conversaciones...'} style={{paddingLeft: '22%'}}/> : null }
        {loadingConversationQuick ? <CustomSpinner status={'Iniciando conversaciónes rápida...'}/> : null}
        {viewCreateUser}
      </div>
    </div>
  );
});

export default ConversationList
