import React, {Component, Fragment} from 'react';
import {InputText} from 'primereact/inputtext';
import {InputTextarea} from 'primereact/inputtextarea';
import {Chips} from 'primereact/chips';
import {Checkbox} from 'primereact/checkbox';
import {RadioButton} from 'primereact/radiobutton';
import {InputSwitch} from 'primereact/inputswitch';
import {Dropdown} from 'primereact/dropdown';
import {Password} from 'primereact/password';
import {Calendar} from 'primereact/calendar';
import {Editor} from 'primereact/editor';
import {ToggleButton} from 'primereact/togglebutton';
import {Button} from 'primereact/button';
import CKEditor from "react-ckeditor-component";
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { addLocale } from 'primereact/api';
import InputContact from "../../UI/InputContact";
import InputSmsGateway from "../SmsSettings/SmsSetting/InputSmsGateway";
import NotificationBody from "../Notifications/Notification/NotificationBody";
import Frecuency from "../Frecuency/Frecuency";
import MultiSelectField from "../../UI/MultiSelectField";
import '../../assets/css/InputField.css'
import CustomTagsInput from "../CustomTags/CustomTagsInput";
import CustomTagList from "../CustomTags/CustomTagList";
import {CompactPicker} from 'react-color';
import {colors} from "../../UI/Colors";
import ReassignMessages from "../Messenger/ReassignMessages/ReassignMessages";
import NotificationWhatsappBody from "../Notifications/Notification/NotificationWhatsappBody";
import MessageNote from "../Messenger/MessageNote/MessageNote";
import MessageForwardContact from "../Messenger/MessageForward/MessageForwardContact";
import ContactSelector from '../Messenger/ContactSelector/ContactSelector';
import ConversationView from "../Messenger/MessageList/ConversationView";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CopyToClipboard from 'react-copy-to-clipboard';

class InputField extends Component {

    constructor(){
        super();
        this.state = { isMobile: false };
        this.inputChangeHandler = this.inputChangeHandler.bind(this);
        this.inputClicked = this.inputClicked.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener("resize", this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateWindowDimensions);
    }

    updateWindowDimensions = () => {
        this.setState({ isMobile: window.innerWidth <= 750 });
    };

    inputChangeHandler(event) {
        let {...custom_event} = event;

        switch(this.props.type){
            case ("checkbox"):
                custom_event['value'] = event.checked;
                break;
            case("editor"):
                custom_event['value'] = event.htmlValue;
                break;
            case("html-editor"):
                custom_event['value'] = event.editor.getData();
                break;
            case('contact'):
                custom_event['value'] = event.value;
                break;
            case('sms'):
                custom_event['value'] = event.value;
                break;
            case('notificationBody'):
                custom_event['value'] = event.value;
                break;
            case('notificationWhatsappBody'):
                custom_event['value'] = event.value;
                break;
            case('multiSelect'):
                custom_event['value'] = event.value;
                break;
            case('frecuency'):
                custom_event['value'] = event.value;
                break;
            case('customTags'):
                custom_event['value'] = event.value;
                break;
            case('customTagList'):
                custom_event['value'] = event.value;
                break;
            case('color'):
                custom_event['value'] = event.hex;
                break;
            case('reassignMessages'):
                custom_event['value'] = event;
                break;
            case('messageNote'):
                custom_event['value'] = event.value;
                break;
            case('messageForwardContact'):
                custom_event['value'] = event.value;
                break;
            case('conversationView'):
                custom_event['value'] = event.value;
                break;
            case('contactSelector'):
                custom_event['value'] = event.value;
                break;
            default:
                custom_event['value'] = event.target.value;
                break;
        }
        this.props.onChange(custom_event);
    }

    inputClicked = (event) => {
        this.props.onClick(event);
    };

    copyValue = () => {
        let copy = null;
        if (this.props.copy){
            copy = (
                <CopyToClipboard style={{top: '15px', right: '10px'}} text={this.props.value}>
                    <FontAwesomeIcon icon={'fa-regular fa-copy'} color={'#FD971F'} size="xs"
                                     className={"btn-actions btn-fontawesome"} />
                </CopyToClipboard>
            )
        }
        return copy;
    }

    inputFormSwitch = () => {
        let inputElement = null;
        let {value, onChange, error, type, traduction, classes, ...properties} = this.props;

        switch (type) {
            case('text'):
                delete properties['elementClass'];
                inputElement =
                    <span className="p-float-label">
                        <InputText
                            {...properties}
                            value={value}
                            onChange={this.inputChangeHandler}
                            className={error && 'p-error'}
                        />
                        <label htmlFor="input">{traduction}</label>
                    </span>
                break;
            case('hidden'):
                inputElement = (
                    <InputText type="hidden" value={value}></InputText>
                );
                break;
            case('contact'):
                inputElement = (
                        <InputContact
                            {...properties}
                            value={value}
                            onChange={this.inputChangeHandler}
                            className={error && 'p-error'}
                            error={error}
                        />
                );
                break;
            case('sms'):
                inputElement = (
                    <InputSmsGateway
                        {...properties}
                        value={value}
                        onChange={this.inputChangeHandler}
                        className={error && 'p-error'}
                        error={error}
                    />
                );
                break;
            case('number'):
                inputElement =
                    <span className="p-float-label">
                        <InputText
                            {...properties}
                            value={value}
                            onChange={this.inputChangeHandler}
                            className={error && 'p-error'}
                            keyfilter={'pnum'}
                        />
                        <label htmlFor="input">{traduction}</label>
                    </span>
                break;
            case('textarea'):
                inputElement =
                    <span className="p-float-label">
                        <InputTextarea
                            {...properties}
                            value={value}
                            onChange={this.inputChangeHandler}
                        />
                        <label htmlFor="textarea">{traduction}</label>
                    </span>
                break;
            case('batch'):
                break;
            case('tags'):
                inputElement =
                    <span className="p-float-label">
                        <Chips
                            {...properties}
                            value={value}
                            onChange={this.inputChangeHandler}
                        />
                        <label htmlFor="tags">{traduction}</label>
                    </span>
                break;
            case('multiSelect'):
                inputElement =
                    <MultiSelectField
                        {...properties}
                        value={value}
                        onChange={this.inputChangeHandler}
                    />
                break;
            case('checkbox'):
                value = value === '' ? false : value;
                inputElement =
                    <div>
                        <Checkbox
                            {...properties}
                            checked={value}
                            onChange={this.inputChangeHandler}
                        />
                        <label htmlFor="checkbox" className="p-checkbox-label p-pl-2">
                            {traduction}
                        </label>
                    </div>
                break;
            case('radio'):
                inputElement =
                    <RadioButton
                        {...properties}
                        value={value}
                        onChange={this.inputChangeHandler}
                    />
                break;
            case('switch'):
                inputElement =
                    <div>
                        <label htmlFor="switch">{traduction}</label>
                        <span className="p-float-label mt-1">
                            <InputSwitch
                                {...properties}
                                onChange={this.inputChangeHandler}
                                checked={value}
                            />
                        </span>
                    </div>
                break;
            case('dropdown'):
                delete properties['placeholder'];
                inputElement =
                    <span className="p-float-label">
                        <Dropdown
                            {...properties}
                            value={value}
                            onChange={this.inputChangeHandler}
                            className={error && 'p-error'}
                            showClear={true}
                            filter={ !this.state.isMobile }
                        />
                     <label htmlFor="dropdown">{traduction}</label>
                    </span>
                break;
            case('password'):
                inputElement =
                    <span className="p-float-label">
                        <Password
                            {...properties}
                            value={value}
                            onChange={this.inputChangeHandler}
                        />
                        <label htmlFor="text">{traduction}</label>
                    </span>
                    
                break;
            case('calendar'):
                addLocale('es', {
                    firstDayOfWeek: 1,
                    dayNames: ["domingo", "lunes", "martes", "miércoles", "jueves", "viernes", "sábado"],
                    dayNamesShort: ["dom", "lun", "mar", "mié", "jue", "vie", "sáb"],
                    dayNamesMin: ["D", "L", "M", "X", "J", "V", "S"],
                    monthNames: ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"],
                    monthNamesShort: ["ene", "feb", "mar", "abr", "may", "jun", "jul", "ago", "sep", "oct", "nov", "dic"]
                });
                inputElement =
                    <span className="p-float-label">
                        <Calendar
                            {...properties}
                            value={value}
                            onChange={this.inputChangeHandler}
                            locale="es"
                            dateFormat={"dd/mm/yy"}
                        />
                        <label htmlFor="calendar">{traduction}</label>
                    </span>
                break;
            case('editor'):
                inputElement =
                    <Editor
                        {...properties}
                        value={value}
                        onTextChange={this.inputChangeHandler}
                    />
                break;
            case('html-editor'):
                inputElement =
                    <CKEditor
                        {...properties}
                        content={value}
                        events={{
                            "change": this.inputChangeHandler
                        }}
                        config={{
                            allowedContent: true,
                            language: 'es',
                            toolbarGroups: [
                                { name: 'clipboard', groups: [ 'clipboard', 'undo' ] },
                                { name: 'editing', groups: [ 'find', 'selection', 'spellchecker', 'editing' ] },
                                { name: 'links', groups: [ 'links' ] },
                                { name: 'insert', groups: [ 'insert' ] },
                                { name: 'forms', groups: [ 'forms' ] },
                                { name: 'tools', groups: [ 'tools' ] },
                                { name: 'document', groups: [ 'mode', 'document', 'doctools' ] },
                                { name: 'others', groups: [ 'others' ] },
                                '/',
                                { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
                                { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi', 'paragraph' ] },
                                { name: 'styles', groups: [ 'styles' ] },
                                { name: 'colors', groups: [ 'colors' ] },
                                { name: 'about', groups: [ 'about' ] }
                            ],
                            removeButtons: 'Subscript,Superscript,PasteFromWord,Scayt,About,Image'
                        }}
                    />
                break;
            case('toggleButton'):
                inputElement =
                    <ToggleButton
                        {...properties}
                        checked={value}
                        onChange={this.inputChangeHandler}
                    />
                break;
            case('button'):
                inputElement =
                    <Button label={value} onClick={this.inputClicked} className={classes} {...properties} type='button' />
                break;
            case('file_upload'):
                registerPlugin(FilePondPluginFileValidateType, FilePondPluginImagePreview);

                let labelIdle = this.props.labelIdle !== undefined ? this.props.labelIdle :
                    'Arrastre y Suelte los archivos o seleccione <span class="filepond--label-action"> Buscar </span>';

                let labelFileLoading = 'Cargando';
                let labelFileLoadError = 'Error en la carga';
                let labelFileProcessing = 'Subiendo';
                let labelFileProcessingComplete = 'Subida completada';
                let labelFileProcessingAborted = 'Subida cancelada';
                let labelFileProcessingError = 'Error en la carga';
                let labelTapToCancel = 'Toque para cancelar';
                let labelTapToRetry = 'Toque para reintentar';
                let labelTapToUndo = 'Toque para deshacer';
                let labelButtonRemoveItem = 'Eliminar';
                let labelButtonAbortItemLoad = 'Abortar';
                let labelButtonRetryItemLoad = 'Reintentar';
                let labelButtonAbortItemProcessing = 'Cancelar';
                let labelButtonUndoItemProcessing = 'Deshacer';
                let labelButtonRetryItemProcessing = 'Reintentar';
                let labelButtonProcessItem = 'Subir';
                let labelFileTypeNotAllowed = 'Tipo de archivo inválido';
                let fileValidateTypeLabelExpectedTypes = 'Esperado {allTypes}'
                let labelMaxFileSizeExceeded = 'Archivo muy grande'
                let labelMaxFileSize = 'Tamaño máximo {filesize}'

                /*let server = {
                    url: this.props.url,
                    process: {
                        withCredentials: true,
                        timeout: 240000,
                        headers: {
                            Authorization: 'Bearer '+ localStorage.getItem('token')
                        }
                    },
                    revert: null
                };*/

                inputElement =
                    <FilePond {...properties} name={type}
                          labelIdle={labelIdle} labelFileLoading={labelFileLoading} labelFileLoadError={labelFileLoadError}
                          labelFileProcessing={labelFileProcessing} labelFileProcessingComplete={labelFileProcessingComplete}
                          labelFileProcessingAborted={labelFileProcessingAborted} labelFileProcessingError={labelFileProcessingError}
                          labelTapToCancel={labelTapToCancel} labelTapToRetry={labelTapToRetry} labelTapToUndo={labelTapToUndo}
                          labelButtonRemoveItem={labelButtonRemoveItem} labelButtonAbortItemLoad={labelButtonAbortItemLoad}
                          labelButtonRetryItemLoad={labelButtonRetryItemLoad} labelButtonAbortItemProcessing={labelButtonAbortItemProcessing}
                          labelButtonUndoItemProcessing={labelButtonUndoItemProcessing} labelButtonRetryItemProcessing={labelButtonRetryItemProcessing}
                          labelButtonProcessItem={labelButtonProcessItem} labelFileTypeNotAllowed={labelFileTypeNotAllowed}
                          fileValidateTypeLabelExpectedTypes={fileValidateTypeLabelExpectedTypes} labelMaxFileSizeExceeded={labelMaxFileSizeExceeded}
                          labelMaxFileSize={labelMaxFileSize}
                    />
                break;
            case('notificationBody'):
                inputElement = (
                    <NotificationBody
                        {...properties}
                        value={value}
                        onChange={this.inputChangeHandler}
                        className={error && 'p-error'}
                        error={error}
                    />
                );
                break;
            case('notificationWhatsappBody'):
                inputElement = (
                    <NotificationWhatsappBody
                        {...properties}
                        value={value}
                        onChange={this.inputChangeHandler}
                        className={error && 'p-error'}
                        error={error}
                    />
                );
                break;
            case('frecuency'):
                inputElement = (
                    <Frecuency
                        {...properties}
                        value={value}
                        onChange={this.inputChangeHandler}
                        className={error && 'p-error'}
                        error={error}
                    />
                );
                break;
            case('customTags'):
                inputElement = (
                    <CustomTagsInput
                        {...properties}
                        value={value}
                        onChange={this.inputChangeHandler}
                        className={error && 'p-error'}
                        error={error}
                    />
                );
                break;
            case('customTagList'):
                inputElement = (
                    <CustomTagList
                        {...properties}
                        value={value}
                        onChange={this.inputChangeHandler}
                        className={error && 'p-error'}
                        error={error}
                    />
                );
                break;
            case('color'):
                inputElement = (
                    <Fragment>
                        <div style={{marginBottom: '1em'}}>
                            {traduction}
                        </div>
                        <CompactPicker
                            color={value}
                            colors={colors}
                            onChange={this.inputChangeHandler}
                        />
                    </Fragment>
                );
                break;
            case('reassignMessages'):
                inputElement = (
                    <Fragment>
                        <ReassignMessages
                            {...properties}
                            value={value}
                            onChange={this.inputChangeHandler}
                        />
                    </Fragment>
                )
                break;
            case('messageNote'):
                inputElement = (
                    <MessageNote
                        {...properties}
                        value={value}
                        onChange={this.inputChangeHandler}
                        className={error && 'p-error'}
                        error={error}
                    />
                );
                break;
            case('messageForwardContact'): 
                inputElement = (
                    <MessageForwardContact
                        {...properties}
                        value={value}
                        onChange={this.inputChangeHandler}
                        className={error && 'p-error'}
                        error={error}
                    />
                );
                break;
            case('contactSelector'):
                inputElement = (
                    <ContactSelector
                        {...properties}
                        value={value}
                        onChange={this.inputChangeHandler}
                        className={error && 'p-error'}
                        error={error}
                    />
                );
                break;
            case('conversationView'):
                inputElement = (
                    <ConversationView
                        {...properties}
                        value={value}
                        onChange={this.inputChangeHandler}
                        className={error && 'p-error'}
                        error={error}
                    />
                )
                break;
            default:
                inputElement =
                    <InputText
                        {...properties}
                        value={value}
                        onChange={this.inputChangeHandler}
                    />
                break;
        }

        return inputElement;

    };

    render() {
        const element = this.inputFormSwitch();
        const copy = this.copyValue();

        let error = this.props.error;
        let smallErrors = this.props.small_errors;
        let showSmallErrors = null;

        if (typeof(error) === 'object'){
            error = undefined;
        }
        let elementClass = this.props.elementClass;

        if (this.props.type === 'dropdown'){
            if (this.props.required === true){
                error = 'Seleccione una opción';
            }
        }

        if (error === undefined){
            if (elementClass === undefined){
                elementClass = "col-12 md-8";
            }
        }

        if (smallErrors !== undefined && smallErrors !== null){
            showSmallErrors = (
                <small style={{color:'#f44336'}} className="p-error">
                    {smallErrors}
                </small>
            )
        }

        let body = (<div className={this.props.elementType !== 'childForm' ? (this.props.elementType === 'button' ? 'col-6 ' : null) : "" }>
                    <div className={this.props.type === 'hidden' ? "hidden-input" : "grid form-group"}>
                        <div className={elementClass || "col-12 md-8"}>
                            {element}
                            {error &&
                            (<div className="col">
                                <small style={{color: 'red'}}>
                                    {error}
                                </small>
                            </div>)
                            }
                        </div>
                        {copy}
                    </div>
                </div>);

        return body;
    }

}

export default InputField;
