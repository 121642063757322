import React, { useState, useEffect } from 'react';
import { CompanyContext } from '../companyContext';
import {getProviderSettings} from '../../utils/session';
import io from "socket.io-client";
import jwtDecode from "jwt-decode";
import { switchRole } from '../../shared/roles';
import axios from 'axios';
import { useFeatureFlags } from '../../utils/flags';

const urlServer = process.env.REACT_APP_API_URL;

const CompanyContextProvider = ({children}) => {
    const [privateInbox, setPrivateInbox] = useState(false);
    const [socket, setSocket] = useState(io(urlServer));
    const [privateGroup, setPrivateGroup] = useState(false);
    const [chatbotEnabled, setChatBotEnabled] = useState(false);
    const [providerTimezone, setProviderTimezone] = useState('America/Argentina/Buenos_Aires');
    const [isTopBarVisible, setIsTopBarVisible] = useState(true);
    const [internalChatEnabled, setInternalChatEnabled] = useState(false);
    const [chatChannel, setChatChannel] = useState('whatsapp');

    const [providerUrlVersion, setProviderUrlVersion] = useState('0.1');
    const [notificationAvailable, setNotificationAvailable] = useState(false);
    const [soundAvailable, setSoundAvailable] = useState(false);
    const [webhookEnabled, setWebhookEnabled] = useState(false);
    const [gatewayWaba, setGatewayWaba] = useState(null);
    const [hasExportClients, setHasExportClients] = useState(false);
    const [prefixTimezone, setPrefixTimezone] = useState('ar');
    const [mrTurnoIntegration, setMrTurnoIntegration] = useState(false);
    const [businessIntegration, setBusinessIntegration] = useState(false);
    const [aiAssistantIntegration, setAiAssistantIntegration] = useState(false);
    const [aiConversationalIntegration, setAiConversationalIntegration] = useState(false);

    const { updateUser } = useFeatureFlags();

    const checkDecodeToken = (token) => {
        try {
            const decoded = jwtDecode(token);
            return decoded
        } catch (err) {
            return null;
        }
    }

    const connectSocket = (token) => {
        if (checkDecodeToken(token)){
            const socketNew = io(urlServer, {
                transports: ['websocket']
            })
            socketNew.on('connect', () => {
                console.log('Conectado al servidor Socket.io');
                socketNew.emit('create', token);
            });

            setSocket(socketNew);
        }
    }

    const desconnectSocket = () => {
        console.log('Desconectado al servidor Socket.io');
        socket.disconnect();
    }

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            connectSocket(token);
            getProviderSettings()
                .then((result) => {
                    if (switchRole() !== 'admin') {
                        setPrivateInbox(result.provider.private_inbox);
                        setPrivateGroup(result.provider.private_group);
                        setProviderTimezone(result.provider.provider_timezone)
                        setPrefixTimezone(result.provider.prefix_timezone)
                        setProviderUrlVersion(result.provider.url_version);
                        setWebhookEnabled(result.provider.webhook_enabled);
                        setGatewayWaba(result.provider.gateway);
                        setHasExportClients(result.provider.export_clients_enabled);
                        setMrTurnoIntegration(result.provider.mr_turno_integration);
                        setInternalChatEnabled(result.provider.internal_chat);
                        setBusinessIntegration(result.provider.business_integration);
                        setAiAssistantIntegration(result.provider.ai_assistant_integration);
                        setAiConversationalIntegration(result.provider.ai_conversational_integration);
                        setChatBotEnabled(result.provider.chatbot_enabled);
                        updateUser(result.provider.url_version);
                    }
                    setNotificationAvailable(result.notification_available);
                    setSoundAvailable(result.sound_available);

                    if (result.provider.url_version){
                        axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}/${result.provider.url_version}`;
                    } else {
                        axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}/0.1`;
                    }

                })
        } else {
            desconnectSocket();
        }
    }, [localStorage.getItem('token')]);

    return (
        <CompanyContext.Provider
          value={{
              privateInbox: privateInbox,
              privateGroup: privateGroup,
              socket: socket,
              providerTimezone: providerTimezone,
              chatbotEnabled: chatbotEnabled,
              providerUrlVersion: providerUrlVersion,
              notificationAvailable: notificationAvailable,
              soundAvailable: soundAvailable,
              webhookEnabled: webhookEnabled,
              gatewayWaba: gatewayWaba,
              isTopBarVisible: isTopBarVisible,
              hasExportClients: hasExportClients,
              internalChatEnabled: internalChatEnabled,
              chatChannel: chatChannel,
              prefixTimezone: prefixTimezone,
              mrTurnoIntegration: mrTurnoIntegration,
              businessIntegration: businessIntegration,
              aiAssistantIntegration: aiAssistantIntegration,
              aiConversationalIntegration: aiConversationalIntegration,
              setPrivateInbox: (e) => setPrivateInbox(e),
              setPrivateGroup: (e) => setPrivateGroup(e),
              setIsTopBarVisible: (e) => setIsTopBarVisible(e),
              setChatChannel: (e)=> setChatChannel(e),
              setProviderTimezone: (e) => setProviderTimezone(e),
              setPrefixTimezone: (e) => setPrefixTimezone(e),
          }}
        >
            {children}
        </CompanyContext.Provider>
    );
};

export default CompanyContextProvider;
