import React, { createContext, useContext, useState, useEffect } from 'react';
import { FbClientBuilder, UserBuilder } from "@featbit/js-client-sdk";

const FeatureFlagsContext = createContext();

export const FeatureFlagProvider = ({ children }) => {
    const [fbClient, setFbClient] = useState(null);
    const [flags, setFlags] = useState({});
    const [availableFlags, setAvailableFlags] = useState(new Set());
    const [currentUser, setCurrentUser] = useState(localStorage.getItem('provider_id'));
    const [betaAccess, setBetaAccess] = useState(false);
    const [featbitReady, setFeatbitReady] = useState(false);

    const initFeatbit = (betaAccess) => {
        if (!currentUser) return;

        const user = new UserBuilder(currentUser)
            .name(currentUser)
            .custom("beta", betaAccess)
            .build();

        try {

            const client = new FbClientBuilder()
                .sdkKey(process.env.REACT_APP_FEATBIT_SDK_KEY)
                .streamingUri(process.env.REACT_APP_FEATBIT_STREAM_URL)
                .eventsUri(process.env.REACT_APP_FEATBIT_EVENT_URL)
                .user(user)
                .build();

            setFbClient(client);

            (async () => {
                try {
                    await client.waitForInitialization();
                    console.log("Flags SDK connection successful");
                    setFeatbitReady(true);

                    const variations = await client.getAllVariations();

                    //console.log("Flags:", variations);

                    const flagKeys = new Set();
                    const initialFlags = {};

                    variations.forEach(({ flagKey, value }) => {
                        flagKeys.add(flagKey);
                        initialFlags[flagKey] = value === "true";
                    });

                    setFlags(initialFlags);
                    setAvailableFlags(flagKeys);

                    client.on("update", (update) => {
                        const flagKey = update[0][0];
                        const newValue = client.boolVariation(flagKey, false);
                        setFlags((prev) => ({ ...prev, [flagKey]: newValue }));
                    });
                } catch (err) {
                    console.error("Error initializing fbClient:", err);
                    const cachedDataStr = localStorage.getItem(`fb-datastore-${currentUser}`);
                    if (cachedDataStr) {
                        try {
                            const cachedData = JSON.parse(cachedDataStr);
                            const cachedFlags = cachedData?.flags;
                            if (cachedFlags && Object.keys(cachedFlags).length > 0) {
                                const flagKeys = new Set();
                                const initialFlags = {};
                                Object.keys(cachedFlags).forEach(flagKey => {
                                    flagKeys.add(flagKey);
                                    initialFlags[flagKey] = cachedFlags[flagKey].variation === "true";
                                });
                                setFlags(initialFlags);
                                setAvailableFlags(flagKeys);
                                setFeatbitReady(true);
                                return;
                            }
                        } catch (parseError) {
                            console.error("Error parsing cached flags:", parseError);
                        }
                    }
                    setFlags({});
                    setAvailableFlags(new Set());
                    setFeatbitReady(true);
                }
            })();

            return () => {
                client.close();
            };

        } catch (err) {
            console.error("Error creating fbClient:", err);
        }
    };

    const updateUser = (access) => {
        const newUser = localStorage.getItem('provider_id');
        const currentVersion = localStorage.getItem('currentUrlVersion');
        const currentAccess = access === '0.2';
        if (newUser !== currentUser || currentVersion !== access) {
            localStorage.setItem('currentUrlVersion', access);
            setBetaAccess(currentAccess);
            setCurrentUser(newUser);
        }
        initFeatbit(currentAccess);
    };

    const getFlag = (flagKey) => {
        if (!featbitReady) return false;
        return flags[flagKey] ?? false;
    };
    const isFlagAvailable = (flagKey) => {
        if (!featbitReady) return false;
        return availableFlags.has(flagKey);
    };

    return (
        <FeatureFlagsContext.Provider value={{ flags, getFlag, fbClient, isFlagAvailable, updateUser }}>
            {children}
        </FeatureFlagsContext.Provider>
    );
};

export const useFeatureFlags = () => useContext(FeatureFlagsContext);

export const useFlag = (flagKey, defaultValue = false) => {
    const { getFlag, isFlagAvailable } = useFeatureFlags();

    if (!isFlagAvailable(flagKey)) {
        console.warn(`Flag "${flagKey}" not found.`);
        return defaultValue;
    }

    return getFlag(flagKey) ?? defaultValue;
  };
