import React, {Fragment, useState, useEffect, useRef} from 'react'

import ReactTooltip from "react-tooltip";
import DialogGeneric from "../../../UI/DialogGeneric";
import axios from "axios";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import './MessageMedia.css'
import { Menu } from 'primereact/menu';

const MessageMedia = (props) => {

    const [modalInfo, setModalInfo] = useState(null);
    const attach = useRef(null);

    const [windowDimensions, setWindowDimensions] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
      });
      useEffect(() => {
        const updateWindowDimensions = () => {
          setWindowDimensions({
            width: window.innerWidth,
            height: window.innerHeight,
          });
        };
        window.addEventListener("resize", updateWindowDimensions);
        return () => window.removeEventListener("resize", updateWindowDimensions);
      }, []);

    const isMobile = () => {
        return window.innerWidth <= 750;
    };

    const itemsAttach = [
        {
            label:
            <i>
                <FontAwesomeIcon icon={"fa-regular fa-file"} style={{width: '1.4em', height: '1.4em', color: '#b421f3'}}/>
                <span className="menu-font-awesome-icon"> Documento </span>
            </i>,
            command: (e) => {
                uploadMedia(e, 'document');
            } 
        },
        {
            label:
            <i>
                <FontAwesomeIcon icon={"fa-regular fa-image"} style={{width: '1.4em', height: '1.4em', color: '#2268ff'}}/>
                <span className="menu-font-awesome-icon"> Imagen </span>
            </i>,
            command: (e) => {
                uploadMedia(e, 'image');
            }
        },
        {
            label:
            <i>
                <FontAwesomeIcon icon={"fa-regular fa-video"} style={{width: '1.4em', height: '1.4em', color: '#b92073'}}/>
                <span className="menu-font-awesome-icon"> Video </span>
            </i>,
            command: (e) => {
                uploadMedia(e, 'video');
            }
        },
        {
            label:
            <i>
                <FontAwesomeIcon icon={"fa-regular fa-comment"} style={{width: '1.4em', height: '1.4em', color: '#ffaa22'}}/>
                <span className="menu-font-awesome-icon"> Nota </span>
            </i>,
            command: (e) => {
                uploadNote(e, 'note');
            }
        },
        {
            label:
            <i>
                <FontAwesomeIcon icon={"fa-regular fa-clock"} style={{width: '1.4em', height: '1.4em', color: '#673AB7'}}/>
                <span className="menu-font-awesome-icon"> Recordatorio </span>
            </i>,
            command: (e) => {
                uploadNote(e, 'note_reminder');
            }
        },
        {
            label:
            <i>
                <FontAwesomeIcon icon={"fa-regular fa-message"} style={{width: '1.4em', height: '1.4em', color: '#a93ab7'}}/>
                <span className="menu-font-awesome-icon"> Mensaje Programado </span>
            </i>,
            command: (e) => {
                uploadNote(e, 'scheduled_message');
            }
        },
        {
            label:
            <i>
                <FontAwesomeIcon icon={"fa-regular fa-comment-dots"} style={{width: '1.4em', height: '1.4em', color: '#009688'}}/>
                <span className="menu-font-awesome-icon"> Respuestas rápidas </span>
            </i>,
            command: () => {
                props.setShowTemplates(true);
            }
        },
        {
            label:
            <i>
                <FontAwesomeIcon icon={"fa-regular fa-address-book"} style={{width: '1.4em', height: '1.4em', color: '#f08080'}}/>
                <span className="menu-font-awesome-icon"> Contacto </span>
            </i>,
            command: () => {
                props.sendContacts();
            }
        },
    ]

    const acceptedMimeTypes = {
        'document': [
            'text/csv',
            'text/plain',
            'application/pdf',
            'application/msword',
            'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            'application/vnd.ms-excel',
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        ],
        'image': [
            'image/png',
            'image/jpeg'
        ],
        'video': [
            'video/mp4',
            'video/3gp'
        ]
    }

    const messageTypeTranslate = {
        'document': 'Documento',
        'image': 'Imagen',
        'video': 'Video'
    }
    const messageTypeSize = {
        'document': '15MB',
        'video': '15MB',
        'image': '5MB'
    }
    const uploadNote = (event, message_type) => {
        props.onMessageMediaCreate(message_type, props.messageType);
    }

    const uploadMedia = (event, message_type) => {
        const value_dialog = {
            'method': 'post',
            'header': 'Adjuntar ' + messageTypeTranslate[message_type],
            'submit_label': 'Subir',
            'sumaryToast': 'Subir '+ messageTypeTranslate[message_type],
            'fields': [
                {
                    name: 'filename',
                    elementType: 'file_upload',
                    elementConfig: {
                        allowMultiple: false,
                        maxFiles: 1,
                        acceptedFileTypes: acceptedMimeTypes[message_type],
                        maxFileSize: messageTypeSize[message_type],
                        labelIdle: 'Arrastre y Suelte el archivo o seleccione <span class="filepond--label-action"> Buscar </span>',
                        server: {
                            url: axios.defaults.baseURL + '/messages/media',
                            process: {
                                withCredentials: true,
                                timeout: 600000,
                                headers: {
                                    Authorization: 'Bearer '+ localStorage.getItem('token')
                                },
                                onerror: (response) => {
                                    props.onMessageMediaError('Error al subir', 'media')
                                },
                                onload: (response) => {
                                    response = JSON.parse(response);

                                    if (response['status_code'] === 200){
                                        props.onMessageMediaCreate(message_type, response.media);
                                    }
                                    props.setShowModal(false);
                                }
                            },
                            revert: null
                        }
                    },
                    elementClass: 'col-12'
                },

            ],
        }
        props.setShowModal(true);
        setModalInfo(<DialogGeneric visible={true} show_footer={false} setVisible={props.setShowModal} {...value_dialog} closeModal={true}/>);

    }

    const attachmentButton = (
        <Fragment>
            <div>
                <Menu style={{width: 'auto'}} model={itemsAttach} popup ref={attach}/>
            </div>
            <button data-tip data-for="attachmentButton" className="p-link" onClick={(event) => {attach.current.toggle(event)}}>
                <FontAwesomeIcon icon="plus" className="btn botonTransp"/>
            </button>
            {!isMobile() &&
                <ReactTooltip id="attachmentButton" place="left" effect="solid">
                    Adjuntar
                </ReactTooltip>
            }
        </Fragment>
    )

    const render_modal = props.showModal ? modalInfo : null;
    return (
        <Fragment>
            {attachmentButton}
            {render_modal}
        </Fragment>
    );
}

export default MessageMedia;
