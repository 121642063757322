import ApiLoan from '../service/ApiLoanService';
import { WHATSAPP } from "../shared/channelTypes";

export const markAsSeen = (status=true, client_id) => {
    const apiService = new ApiLoan();
    apiService.postResource({
        url: '/conversations/' + client_id + '/seen',
        data: {
          unread_messages: !status
        }
    });
}

export const markAsPinup = (status=true, client_id) => {
    const apiService = new ApiLoan();
    apiService.postResource({
        url: '/conversations/' + client_id + '/pinup',
        data: {
          pinup_messages: status
        }
    });
}

export const markAsArchived = (status=true, client_id) => {
    const apiService = new ApiLoan();
    apiService.postResource({
        url: '/conversations/' + client_id + '/archived',
        data: {
          archived: status
        }
    });
}

export const markAsStar = (message_id) => {
    const apiService = new ApiLoan();
    apiService.postResource({
        url: '/message/' + message_id + '/star',
    });
}

export const internalMarkAsStar = (message_id) => {
    const apiService = new ApiLoan();
    apiService.postResource({
        url: '/internal_message/' + message_id + '/star',
    });
}

export const markAsArchivedAndClean = (status= true, conversation= false, custom_tags= false,
                                       client_id) => {
    const apiService = new ApiLoan();
    apiService.postResource({
        url: '/conversations/' + client_id + '/clean',
        data: {
          archived: status,
          clean_conversation: conversation,
          clean_custom_tags: custom_tags,
        }
    });
}

export const toggleAssign = (client_id, user_id, toggle_assign, props) => {
    const apiService = new ApiLoan();
    return apiService.postResource({
        url: '/conversations/' + client_id + '/assign',
        data: {
            user_id: user_id,
            toggle_assign: toggle_assign,
            recipient: props.data.client.contacts[0].value,
            country_prefix: props.data.client.contacts[0].country_prefix,
            channel_id: WHATSAPP
        }
    });
}

export const getScrollPointers = (response, scrollDirection, scrollPointers, messagePage) => {
    let scrollPointerAsc = [null, 0, 2].includes(scrollDirection) ? response.data?.previous_uuid : scrollPointers[0];
    let scrollPointerDesc = [null, 1, 2].includes(scrollDirection) ? response.data?.following_uuid : scrollPointers[1];

    if (!messagePage) {
        messagePage = response.data.total_pages
    }

    let moreMessagesAsc = response.data?.total_pages ? messagePage < response.data.total_pages : scrollPointerAsc != null;
    let moreMessagesDesc = scrollPointerDesc != null;

    return [scrollPointerAsc, scrollPointerDesc, moreMessagesAsc, moreMessagesDesc];
}
