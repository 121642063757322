import React from 'react';

const MessageNotFount = () => {
    return (
        <div className="result-not-fount">
          <p>No se encontró ningún mensaje.</p>
        </div>
    );
}

export default MessageNotFount;