import axios from 'axios';

export const axiosInstances = {
    "0.1": axios.create({
        baseURL: process.env.REACT_APP_API_URL + '/0.1',
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        }
    }),
    "0.2": axios.create({
        baseURL: process.env.REACT_APP_API_URL + '/0.2',
        headers: {
          'Authorization': 'Bearer ' + localStorage.getItem('token'),
        }
    })
};

Object.values(axiosInstances).forEach(instance => {
    instance.interceptors.request.use(config => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token;
            config.baseURL = `${process.env.REACT_APP_API_URL}/${localStorage.getItem('urlVersion')}`;
        }
        return config;
    });
});

export default class ApiLoan {

    constructor() {
        const urlVersion = localStorage.getItem('urlVersion');
        this.api = axiosInstances[urlVersion] || axiosInstances["0.1"];
    }

    getResources(args={}){
        let url;
        let cancelToken;

        let q = {};

        if (args.hasOwnProperty("url")) {
            url = args.url;
        }

        if (args.hasOwnProperty("filters")) {
            q["filters"] = args.filters;
        }
        if (args.hasOwnProperty("order_by")) {
            q["order_by"] = args.order_by;
        }
        if (args.hasOwnProperty("group_by")) {
            q["group_by"] = args.group_by;
        }
        if (args.hasOwnProperty("cancelToken")) {
            cancelToken = args.cancelToken;
        }

        const paramKeys = [
            "page", "results_per_page", "search_query", "selected_user", "selected_group", "pinup_messages",
            "unread_messages", "archived", "not_assigned", "start_date", "end_date", "from_id", "recently_transferred",
            "searched_msg_uuid", "scroll_direction",
        ];

        let params = paramKeys.reduce((acc, key) => {
           if (args.hasOwnProperty(key)) {
               acc[key] = args[key];
           }
           return acc;
        }, {});

        if (Object.keys(q).length > 0) {
            params["q"] = q;
        }

        if (args.hasOwnProperty("validateEmail") && args.validateEmail) {
            url = "/validate_email";
            params = { email: args.email };
        }
        return this.api.get(url, { cancelToken, params });
    }

    getResource(args={}){
        let url = '';
        let resource_id = '';
        if (args.hasOwnProperty('url')){
            url = args.url;
        }
        if (args.hasOwnProperty('resource_id')){
            resource_id = args.resource_id;
            url += resource_id;
        }

        return this.api.get(url);
    }

    getResourceBlob(args={}){
        let url = '';
        if (args.hasOwnProperty('url')){
            url = args.url;
        }

        const paramKeys = ["min_date", "max_date", "gateway_id"];

        const params = paramKeys.reduce((acc, key) => {
            if (args.hasOwnProperty(key)) {
                acc[key] = args[key];
            }
            return acc;
        }, {});

        return this.api.get(url, { params, responseType: 'blob' });
    }


    postResource(args={}){
        let url = '';
        let formData = {};

        let responseType = args.responseType || 'json';

        if (args.hasOwnProperty('url')){
            url = args.url;
        }
        if (args.hasOwnProperty('data')){
            formData = args.data;
        }

        const paramKeys = [
            "start_date", "end_date",
        ];

        const params = paramKeys.reduce((acc, key) => {
           if (args.hasOwnProperty(key)) {
               acc[key] = args[key];
           }
           return acc;
        }, {});


        return this.api.post(url, formData, { params, responseType });
    }

    postResourceBlob(args={}){
        let url = '';
        let formData = {};

        if (args.hasOwnProperty('url')){
            url = args.url;
        }
        if (args.hasOwnProperty('data')){
            formData = args.data;
        }

        const headers = {
            'Content-Type': args.blob.type
        }

        return this.api.post(url, formData, { headers });
    }

    patchResource(args={}){
        let { url = '', resource_id, data: formData = {} } = args;

        if (resource_id) {
            if (url.endsWith('/')) {
                url = url.slice(0, -1);
            }
            url = `${url}/${resource_id}`;
        }

        return this.api.patch(url, formData);
    }

    deleteResource(args={}){
        let url = '';
        let resource_id = '';

        if (args.hasOwnProperty('url')){
            url = args.url;
        }

        if (args.hasOwnProperty('resource_id')){
            resource_id = args.resource_id;
            if (url.endsWith('/')) {
              url = url.slice(0, -1);
            }
            url = `${url}/${resource_id}`;
        }
        return this.api.delete(url);
    }

}
