import React, { useState, useRef, useEffect, useContext } from 'react';
import { connect } from 'react-redux';
import classNames from "classnames";
import { withRouter } from 'react-router';
import AppTopbar from "../AppTopbar";
import AppMenu from "../AppMenu";
import Auth from "../containers/Auth/Auth";
import * as roles from "../shared/roles";
import { Toast } from 'primereact/toast';
import {clearMessages} from '../store/actions/index';
import PrimeReact from 'primereact/api';
import {TableContext} from "../context/tableContext";
import { useHistory } from 'react-router-dom';
import ApiLoan from "../service/ApiLoanService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { CompanyContext } from "../context/companyContext"
import { OverlayPanel } from 'primereact/overlaypanel';
import './Layout.css'

const Layout = (props) => {
    const [activeTopbarItem, setActiveTopbarItem] = useState(null);
    const [layoutMode, setLayoutMode] = useState('static');
    const [mobileMenuActive, setMobileMenuActive] = useState(null);
    const [topbarMenuActive, setTopbarMenuActive] = useState(null);
    const [menuActive, setMenuActive] = useState(false);
    const [darkMenu, setDarkMenu] = useState(false);
    const [colorMode, setColorMode] = useState('light');
    const sidebar = useRef(null);
    const [statusOpenWa, setStatusOpenWa] = useState(null);
    const companyContext = useContext(CompanyContext);
    const [tableFields, setTableFields] = useState({
        url: null,
        filters: null,
        page: 0,
        first: 0,
        order_by: null,
        sort: {
            sortOrder: null,
            sortField: null
        },
        reset: false
    });

    const [menu, setMenu] = useState(null);
    const [qrMode, setQrMode] = useState(false);
    const [hasJwtUrl, setHasJwtUrl] = useState(false);

    const [height, setHeight] = useState(window.innerWidth);
    const op = useRef(null);
    const [help, setHelp] = useState(false);
    const [leaveClick, setLeaveClick] = useState(false);
    const socket = companyContext.socket;

    const {isTopBarVisible, aiAssistantEnabled, gatewayWaba, webhookEnabled,
        chatbotEnabled, notificationAvailable, mrTurnoIntegration, businessIntegration,
        hasExportClients, internalChatEnabled, aiConversationalIntegration,
        setChatChannel} = companyContext;

    const layoutContentClasses = `layout-content ${!isTopBarVisible ? "layout-content-tb-hidden" : ""}`;

    useEffect(() => {
        const updateWindowDimensions = () => {
            const newHeight = window.innerWidth;
            setHeight(newHeight);
        };

        window.addEventListener("resize", updateWindowDimensions);

        return () => window.removeEventListener("resize", updateWindowDimensions);

    }, []);

    useEffect(() => {
        if (notificationAvailable){
            if ('serviceWorker' in navigator ) {
                navigator.serviceWorker.ready
                  .then(function(registration) {
                    return registration.pushManager.getSubscription()
                      .then(function(subscription) {
                        if (subscription) {
                          return subscription;
                        }
                        return registration.pushManager.subscribe({
                          userVisibleOnly: true,
                          applicationServerKey: 'BGwS2mqLwPzxT517-96Lz1OuPjRvYpCf2dB8VX3waO4FaUl3AAgIPEERwym0aZvfRMBX7hyHuEVWpyc6mEgR6qE'
                        });
                      });
                  }).catch(function() {
                    toast.current.show({severity:'error', summary: 'Notificacion', detail:'El permiso de notificaciones ha sido denegado', life: 3000});
                  });
              }else {
                toast.current.show({severity:'error', summary: 'Notificacion', detail:'El navegador no admite la notificaciones', life: 3000});
              }
        }
    }, [notificationAvailable, props.location.pathname]);

    useEffect(() => {
        socket.on('update-status-open-wp', updatestatusOpenWa);
        return () => {
            socket.off('update-status-open-wp', updatestatusOpenWa);
        }
    },[companyContext.privateInbox]);

    useEffect(() => {
        socket.on('refresh-page', refreshPage);
        return () => {
            socket.off('refresh-page', refreshPage);
        }
    },[gatewayWaba]);

    const updatestatusOpenWa = ({update_status_open_wp}) => {
        setStatusOpenWa(update_status_open_wp);
    }

    const refreshPage = () => {
        window.location.href = window.location.href;
        window.location.reload(true);
    };

    useEffect(() => {
        let role = localStorage.getItem('role');
        if ((role === '4' || role === '9') && gatewayWaba === 'OPEN-WA'){
            const apiLoan = new ApiLoan();
            apiLoan.getResources({
            url: '/info/session/open_wa'
            }).then((response) => {
                if(response?.data?.msg){
                    setQrMode(true);
                    history.push( '/setting_whatsapp' );
                }
                else {
                    if(response?.data?.pushname){
                        setQrMode(false);
                    }
                }
            });
        }
    },[statusOpenWa, props.location.pathname, gatewayWaba]);


    PrimeReact.ripple = false;

    let menuClick;
    let menuButtonClick;
    let topbarMenuClick;
    let topbarMenuButtonClick;
    let sidebarTimeout;

    const history = useHistory();

    useEffect(() => {
        if (process.env.REACT_APP_PAGE === 'pip-app'){
            changeLayout('pip');
        } else if (process.env.REACT_APP_PAGE === 'prontocobro') {
            changeLayout('prontocobro');
        }
        setChatChannel('whatsapp');
    }, []);

    useEffect(() => {
        let mode = checkModeDark();
        onColorModeChange(mode);
    },[localStorage.getItem('dark')])

    useEffect(() => {
        if (props.isAuthenticated){
            let menu_items = createMenu();
            setMenu(menu_items)
        }
    }, [props.isAuthenticated, webhookEnabled, gatewayWaba, companyContext.chatbotEnabled, mrTurnoIntegration, aiAssistantEnabled]);

    useEffect(() => {
        let jwt_url = localStorage.getItem('jwtUrl');
        setHasJwtUrl(jwt_url !== null)
    }, []);

   const checkModeDark = () => {
    if (localStorage.getItem('dark')) {
        if (localStorage.getItem('dark') === 'true') {
            return 'dark';
        } else {
            return 'light';
        }
    } else {
        return 'light';
    }
   }

   const onColorModeChange = (mode) => {
    setDarkMenu(mode === 'dark');
    setColorMode(mode);

    let element = document.getElementById('theme-css');
    let urlTokens = element.getAttribute('href').split('/');
    urlTokens[urlTokens.length - 1] = 'theme-' + mode + '.css';
    let newURL = urlTokens.join('/');

    element.setAttribute('href', newURL);
    };


    const onWrapperClick = (event) => {
        if (!menuClick && !menuButtonClick && mobileMenuActive) {
            setMobileMenuActive(false)
        }

        if (!topbarMenuClick && !topbarMenuButtonClick) {
            setActiveTopbarItem(null);
            setTopbarMenuActive(false)
        }

        if (!menuClick) {
            if (isHorizontal() || isOverlay()) {
                setMenuActive(false)
            }
        }

        menuClick = false;
        menuButtonClick = false;
        topbarMenuClick = false;
        topbarMenuButtonClick = false;
    }

    const onTopbarItemClick = (event) => {
        topbarMenuClick = true;
        if (activeTopbarItem === event.item)
            setActiveTopbarItem(null)
        else
            setActiveTopbarItem(event.item)
        event.originalEvent.preventDefault();
    }

    const onMenuButtonClick = (event) => {
        menuButtonClick = true;

        if (isMobile()) {
            setMobileMenuActive(prevState => !prevState);
        }

        event.preventDefault();
    }

    const onTopbarMobileMenuButtonClick = (event) => {
        topbarMenuButtonClick = true;
        setTopbarMenuActive(prevState => !prevState)
        event.preventDefault();
    }

    const goDashboard = () => {
       if (history.location.pathname !== '/'){
           history.push('/');
       }
    }

    const onSidebarClick = (click) => {
        setLeaveClick(false);
        menuClick = click;
    }

    const onSidebarMouseLeave = (event) => {
        if (layoutMode !== 'horziontal') {
            setMenuActive(false);
        }
        sidebarTimeout = setTimeout(() => {
            removeClass(sidebar.current, 'layout-sidebar-active');
        }, 250);
    }

    const removeClass = (element, className) => {
        if (element) {
            if (element.classList)
                element.classList.remove(className);
            else
                element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
        }
    }

    const onRootMenuItemClick = (event) => {
        setMenuActive(prevState => !prevState)
    }

    const onMenuItemClick = (event) => {
        if (!event.item.items && isHorizontal()) {
            setMenuActive(false)
        }
        if (!event.item.items && isMobile()) {
            setMobileMenuActive(false);
            setMenuActive(false);
            onSidebarMouseLeave();
        }
    }

    const changeTheme = (event) => {
        changeStyleSheetUrl('theme-css', event, 'theme');
    }

    const changeLayout = (event) => {
        changeStyleSheetUrl('layout-css', event, 'layout');
    }

    const changeStyleSheetUrl = (id, value, prefix) => {
        let element = document.getElementById(id);
        let urlTokens = element.getAttribute('href').split('/');
        urlTokens[urlTokens.length - 1] = prefix + '-' + value + '.css';
        let newURL = urlTokens.join('/');

        replaceLink(element, newURL);
    }

    const isIE = () => {
        return /(MSIE|Trident\/|Edge\/)/i.test(window.navigator.userAgent)
    }

    const replaceLink = (linkElement, href) => {
        if (isIE()) {
            linkElement.setAttribute('href', href);
        }
        else {
            const id = linkElement.getAttribute('id');
            const cloneLinkElement = linkElement.cloneNode(true);

            cloneLinkElement.setAttribute('href', href);
            cloneLinkElement.setAttribute('id', id + '-clone');

            linkElement.parentNode.insertBefore(cloneLinkElement, linkElement.nextSibling);

            cloneLinkElement.addEventListener('load', () => {
                linkElement.remove();
                cloneLinkElement.setAttribute('id', id);
            });
        }
    }

    const isMobile = () => {
        return window.innerWidth <= 1024;
    }

    const isOverlay = () => {
        return layoutMode === 'overlay';
    }

    const isHorizontal = () => {
        return layoutMode === 'horizontal';
    }



    const createMenu = () => {
        let role = localStorage.getItem('role');
        let menu_items = null;

        if (role === null){
            return menu_items;
        }

        let supervisor_menu = {
            'menu': ['audience', 'upload', 'params', 'reports'],
            'submenus': [
                'upload.create_clients', 'params.notifications', 'params.tags', 'params.email_setting',
                'params.info_provider', 'params.account_settings', 'params.create_users',
                'params.webhooks', 'params.scheduled.mr_turno', 'params.integrations.business', 'upload.tasks',
                'params.chats.chat_bot', 'params.internal_chats.internal_groups', 'params.ai_assistant',
                'params.groups', 'params.conversational_ai_assistant', 'reports.report_notification',
                'reports.client_export', 'reports.whatsapp', 'params.setting_whatsapp'
            ]
        }

        let audience_menu = {
            'menu': ['audience', 'params'],
            'submenus': [
                'params.tags'
            ]
        }

        let admin_menu = {
            'menu': ['params', 'reports', 'admin'],
            'submenus': [
                'params.email_setting', 'params.sms_settings', 'params.ai_assistant', 'reports.report_notification',
                'admin.clients', 'admin.channels', 'admin.users'
            ]
        }

        menu_items = [
            {
                items: [
                    {
                        label: 'Contacto', slug: 'audience', icon: 'fa-regular fa-users', to: '/audiences'
                    },
                    {
                        label: 'Clientes', slug: 'audience_clients', icon: 'users', to: '/audiences'
                    },
                    {
                        label: 'Cargas', icon: 'fa-regular fa-cloud-upload-alt', slug: 'upload',
                        items: [
                            {label: 'Clientes', slug: 'create_clients', icon: 'fa-regular fa-people-arrows', to: '/clients/bulk'},
                            {label: 'Tareas', icon: 'fa-regular fa-tasks', slug: 'tasks',  to: '/tasks'},
                        ]
                    },
                    {
                        label: 'Ajustes', icon: 'fa-regular fa-gear', slug: 'params',
                        items: [
                            {label: 'Notificaciones', slug: 'notifications', icon: 'fa-regular fa-bell', to: '/notifications'},
                            {label: 'Tags', slug: 'tags', icon: 'fa-regular fa-tag', to: '/custom_tags'},
                            {label: 'Cuenta Email', slug: 'email_setting', icon: 'fa-regular fa-envelope', to: '/email_setting'},
                            {label: 'Cuentas SMS', slug: 'sms_settings', icon: 'sms', to: '/sms_settings'},
                            {label: 'Datos Empresa', slug: 'info_provider', icon: 'fa-regular fa-file-alt', to: '/client/info'},
                            {label: 'Configuración', icon: 'fa-regular fa-user-tie', slug: 'account_settings',  to: '/account/settings'},
                            {label: 'Usuarios', slug: 'create_users', icon: 'fa-regular fa-user', to:'/users'},
                            {label: 'Webhooks', icon: 'fa-regular fa-share-alt', slug: 'webhooks',  to: '/provider_webhooks'},
                            {
                                label: 'Mensaje Programado', icon: 'fa-regular fa-inbox', slug: 'scheduled',
                                items: [
                                    {label: 'Mr Turno', icon: 'fa-regular fa-user-doctor', slug: 'mr_turno',  to: '/provider_scheduled/mr_turno'}
                                ],
                            },
                            {
                                label: 'Integraciones', icon: 'fa-regular fa-inbox', slug: 'integrations',
                                items: [
                                    {label: 'Business', icon: 'fa-regular fa-user-doctor', slug: 'business',  to: '/provider_scheduled/business'},
                                ],
                            },

                            {label: 'Whatsapp Web ', icon: ['fab', 'whatsapp'], slug: 'setting_whatsapp',  to: '/setting_whatsapp'},
                            {
                                label: 'Bot', icon: 'fa-regular fa-robot', slug: 'chats',
                                items: [
                                    {label: 'Cuestionarios', icon: 'fa-regular fa-file-pen', slug: 'chat_bot',  to: '/questionnaires'},
                                    {label: 'Frecuencias', icon: 'fa-regular fa-calendar-day', slug: 'chat_bot',  to: '/chat_bots'},
                                    {label: 'Impulsar', icon: 'fa-regular fa-paper-plane', slug: 'chat_bot',  to: '/chat_bots/push'},
                                ],
                            },
                            {label: 'IA', slug: 'ai_assistant', icon: 'fa-regular fa-head-side-gear', to: '/ai_assistants'},
                            {label: 'IA Conversacional', slug: 'conversational_ai_assistant', icon: 'fa-regular fa-head-side-gear', to: '/conversational_ai_assistants'},
                            {label: 'Equipos', icon: 'fa-regular fa-people-group', slug: 'groups',  to: '/groups'},
                            {
                                label: 'Chat Interno', icon: 'fa-regular fa-comments', slug: 'internal_chats',
                                items: [
                                    {label: 'Grupos', icon: 'fa-regular fa-people-group', slug: 'internal_groups',  to: '/internal_groups'},
                                ],
                            },
                        ]
                    },
                    {
                        label: 'Reportes', icon: 'fa-regular fa-chart-line', slug: 'reports',
                        items: [
                            {label: 'Notificaciones', slug: 'report_notification', icon: 'fa-regular fa-bell', to: '/reports/notification'},
                            {label: 'Exportar', slug: 'report_export', icon: 'fa-regular fa-file-export', to: '/reports/export'},
                            {label: 'Exportar Clientes', slug: 'client_export', icon: 'fa-regular fa-users', to: '/reports/client'},
                            {label: 'Whatsapp', slug: 'whatsapp', icon: ['fab', 'whatsapp'], to: '/analytics/whatsapp'},
                        ]
                    },
                    {
                        label: 'Administrador', icon: 'users-cog', slug: 'admin',
                        items: [
                            {label: 'Clientes', slug: 'clients', icon: 'users', to: '/clients'},
                            {label: 'Canales', slug: 'channels', icon: 'bullhorn', to:'/channels'},
                            {label: 'Usuarios', slug: 'users', icon: 'user-plus', to:'/users'},
                        ]
                    },
                ]
            },



        ];

        let menuRole = null;

        switch (role) {
            case roles.ADMIN:
                menuRole = admin_menu;
                break;
            case roles.AUDIENCE:
                menuRole = audience_menu;
                break;
            case roles.SUPERVISOR:
                menuRole = supervisor_menu;
                break;
            default: return null;
        }

        if (webhookEnabled === false){
            menuRole.submenus = menuRole.submenus.filter(x => x !== 'params.webhooks')
        }

        if (mrTurnoIntegration === false){
            menuRole.submenus = menuRole.submenus.filter(x => x !== 'params.scheduled.mr_turno')
        }

        if (businessIntegration === false){
            menuRole.submenus = menuRole.submenus.filter(x => x !== 'params.integrations.business')
        }

        if (hasExportClients === false){
            menuRole.submenus = menuRole.submenus.filter(x => x !== 'reports.client_export')
        }

        if (aiAssistantEnabled === false){
            menuRole.submenus = menuRole.submenus.filter(x => x !== 'params.ai_assistant')
        }

        if (chatbotEnabled === false) {
            menuRole.submenus = menuRole.submenus.filter(x => x !== 'params.chats.chat_bot')
        }

        if (internalChatEnabled === false) {
            menuRole.submenus = menuRole.submenus.filter(x => x !== 'params.internal_chats.internal_groups')
        }

        if (aiConversationalIntegration === false){
            menuRole.submenus = menuRole.submenus.filter(x => x !== 'params.conversational_ai_assistant');
        }

        if (gatewayWaba !== 'OPEN-WA'){
            menuRole.submenus = menuRole.submenus.filter(x => x !== 'params.setting_whatsapp')
            menuRole.submenus = menuRole.submenus.filter(x => x !== 'params.scheduled')
        }

        let elementsMenu = [];

        let items = menu_items[0].items;

        const menus = menuRole.menu;
        const submenus = menuRole.submenus;

        //menus
        menus.map(row => {
            items.filter(element => {
                if (element.slug === row){
                    if (element.items === undefined){
                        elementsMenu.push(element);
                    } else {
                        let element_copy = {...element};
                        element_copy.items = [];
                        elementsMenu.push(element_copy);
                    }
                }
                return element;
            });

        });
        // submenus
        submenus.map(row => {
            let item = row.split('.');
            if (elementsMenu.filter(x => x.slug === item[0])[0] !== undefined){
                let indexItems = items.findIndex((x) => x.slug === item[0]);
                items[indexItems].items.filter(submenu => {
                    for (let i = 1; i <= item.length - 1; i++) {
                        if (submenu.slug === item[i]){
                            let indexElementsMenu = elementsMenu.findIndex((x) => x.slug === item[0]);
                            elementsMenu[indexElementsMenu].items.push(submenu);
                        }
                    }
                });
            }
        });

        menu_items = [{items: elementsMenu}];
        return menu_items

    }

    const updateTableFields = (data) => {
        let datatableFields = {
            ...tableFields
        };

        for (let key in Object.keys(data)){
            datatableFields[Object.keys(data)[key]] = Object.values(data)[key];
        }

        setTableFields(datatableFields);
    };


    const checkCurrentTable = (urisAvailable, fromOrigin) => {
        let can_clear_fields = true;
        for (let uri in urisAvailable){
            let matched = fromOrigin.match(urisAvailable[uri]);
            if (matched !== null){
                can_clear_fields = false;
                break;
            }
        }

        if (can_clear_fields){
            cleanFieldsTable();
        }

        return can_clear_fields;

    };

    const popUpsHelp = () => {
        return (
            <div style={{cursor: 'pointer'}}>
                <div className={help ? "custom-backdrop" : "custom-without-backdrop"}>
                    <OverlayPanel onHide={(e) => e === undefined ? setHelp(false) : setHelp(true)} ref={op} showCloseIcon id="overlay_panel">
                        <h2><b>Hola!</b></h2>
                        <p>Aquí encontrarás todo lo necesario para comenzar:</p>
                        <p> <FontAwesomeIcon icon='exclamation-circle' color='#19418BE5' size={"lg"}/>
                            <a href='https://fintechsolutions.io/instructivo/' target="_blank" rel="noopener noreferrer">
                                <b> Primeros pasos con Pip!</b>
                                <br></br>
                            </a>
                            Este instructivo te ayudará a iniciar rápidamente con nuestra plataforma. <br></br>
                            Podrás ver descripta cada una de las funcionalidades de Pip!.
                        </p>
                        <p> <FontAwesomeIcon icon='question-circle' color='#19418BE5' size={"lg"}/>
                            <a href='https://fintechsolutions.io/preguntas-frecuentes/' target="_blank" rel="noopener noreferrer">
                                <b> Preguntas frecuentes.</b>
                                <br></br>
                            </a>
                            Una sección que te servirá para despejar cualquier duda que tengas sobre Pip! <br></br>
                            Además, conocerás en detalle las principales diferencias de cada integración.
                        </p>
                    </OverlayPanel>
                </div>
                <a onMouseOver={(e) => onHelp(e)} style={{top: '95%',position: 'absolute'}} rel="noopener noreferrer">
                    <FontAwesomeIcon icon='exclamation-circle' style={{right:'-192px', position: 'relative', width: '1.4em', height: '1.4em'}} size={"lg"}/>
                    <span style={{fontSize: '20px',left:'4px',position: 'relative'}} >Ayuda</span>
                </a>
        </div>
        )
    }

    const onHelp = (event) => {
        op.current.toggle(event);
        help ? setHelp(false) : setHelp(true);
    }

    const cleanFieldsTable = () => {
        let fields = {
            url: null,
            filters: null,
            page: 0,
            first: 0,
            orderBy: null,
            sort: {
                sortOrder: null,
                sortField: null
            },
            reset: false
        };
        setTableFields(fields);
    };

    let wrapperClass = classNames('layout-wrapper', {
        'layout-wrapper-static': layoutMode === 'overlay',
        'layout-wrapper-active': mobileMenuActive,
        'layout-menu-horizontal': layoutMode === 'horizontal',
    });
    let sidebarClassName = classNames("layout-sidebar", { 'layout-sidebar-dark': darkMenu });
    let sidebarQrClassName = qrMode ? {zIndex: '1020'} : {width: ''};

    const toast = useRef(null);

    const getBody = () => {
        let body = null;


        const {messagesToast} = props;
        if (messagesToast) {
            if (messagesToast.length > 0) {
                messagesToast.map(message => {
                    toast.current.show(message);
                    return message;
                });
            }
            props.clearMessages();
        }


        if (props.isAuthenticated){
            if (hasJwtUrl){
                body = props.children;
            } else {
                body = (
                    <div className={wrapperClass} onClick={onWrapperClick}>
                        <div ref={sidebar} style={sidebarQrClassName} className={sidebarClassName}>
                            <div className="sidebar-logo">
                            {!isMobile() &&
                            <div>
                                <i onClick={goDashboard}>
                                    <img
                                        alt="logo"
                                        src={process.env.REACT_APP_PAGE === 'pip-app' ? `${process.env.REACT_APP_PAGE}/images/logo-blanco.png` : `${process.env.REACT_APP_PAGE}/favicon.ico`}
                                        style={{ height:"48px", width:"auto"}}
                                    />
                                </i>
                            </div>
                            }
                            </div>
                            <div className="layout-menu-container" >
                                <AppMenu onSidebarClick={onSidebarClick} leaveClick={leaveClick} model={menu} onRootMenuItemClick={onRootMenuItemClick} layoutMode={layoutMode}
                                         active={menuActive} colorMode={colorMode} onMenuItemClick={onMenuItemClick} />
                            </div>
                        </div>
                        <div onClick={()=> setLeaveClick(true)} className="layout-main">
                            <AppTopbar layoutMode={layoutMode} activeTopbarItem={activeTopbarItem}
                                       onTopbarItemClick={onTopbarItemClick} onMenuButtonClick={onMenuButtonClick}
                                       onTopbarMobileMenuButtonClick={onTopbarMobileMenuButtonClick}
                                       topbarMenuActive={topbarMenuActive} isMobile={isMobile}/>
                            <div className={layoutContentClasses}>
                                <Toast ref={toast} />
                                <TableContext.Provider
                                    value={{
                                        tableFields: tableFields,
                                        updateTableFields: (e) => updateTableFields(e),
                                        checkCurrentTable: (e, t) => checkCurrentTable(e, t),
                                        cleanFieldsTable: (e) => cleanFieldsTable(e),
                                    }}>
                                    {props.children}
                                </TableContext.Provider>
                            </div>
                            {mobileMenuActive && <div className="layout-main-mask"></div>}
                        </div>
                    </div>
                );
            }
        } else {
            if (props.location.pathname === '/' || props.location.pathname === '/auth' ){
                body = (
                    <div>
                        <Toast ref={toast} />
                        <Auth> </Auth>
                    </div>
                );
            } else {
                body = props.children;
            }

        }

        return body;
    }

    return getBody();

}


const mapStateToProps = state => {
    return {
        isAuthenticated: state.auth.token !== null,
        messagesToast: state.message.messages
    };
};

const mapDispatchToProps = dispatch => {
    return {
        clearMessages: () => dispatch(clearMessages())
    };
};


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));