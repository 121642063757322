import "./ContactCarousel.css";
import Avatar from 'react-avatar';
import { Carousel } from "primereact/carousel";
import { Badge } from "primereact/badge";
import { useState, useMemo } from "react";
import { removeNumbersAndSpecialCharacters, avatarColors } from '../../../utils/utils';

const ContactCarousel = (props) => {
    const { contactJSON, contactButtons, out } = props;
    const [page, setPage] = useState(0);

    const isMobile = () => {
        return window.innerWidth <= 1025;
    };

    const productTemplate = useMemo(() => {
        return (contact) => {
            return (
                <div className={out ? "own-contact-card" : "other-contact-card"}>
                    <div className={'flex'}>
                        {!isMobile() &&
                            <div className={'mr-3'}>
                                <div className="mt-4">
                                    <Avatar colors={avatarColors} maxInitials={1} className="conversation-photo p-ac-center" name={removeNumbersAndSpecialCharacters(contact.vcard_name)} round={true} size="60" />
                                </div>
                            </div>
                        }
                        <div className="vcard-info">
                            <div className="vcard-info-name">
                                {contact.vcard_name}
                            </div>
                            <div className="vcard-info-phone">
                                {contactButtons[page]}
                            </div>
                            <div className="vcard-info-mail">
                                {contact.vcard_emails.length > 0 && (contact.vcard_emails[0])}
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }, [out, isMobile, contactButtons, page]);

    if (contactJSON.vcards.length > 1){
        return (
            <div className="carousel-card">
                <Carousel
                    value={contactJSON.vcards}
                    numVisible={1}
                    numScroll={1}
                    itemTemplate={productTemplate}
                    showIndicators={false}
                    page={page}
                    onPageChange={(e) => setPage(e.page)}
                />
                <div className='carousel-badge-div'>
                    <Badge className={out ? "own-carousel-badge" : "other-carousel-badge"} value={`${page + 1} de ${contactJSON.vcards.length}`}></Badge>
                </div>
            </div>
        )
    } else {
        return (
            <div>
                {productTemplate(contactJSON.vcards[0])}
            </div>
        )
    }
}

export default ContactCarousel;
