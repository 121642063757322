import * as actionTypes from './actionTypes';
import axios from 'axios';
import jwtDecode from 'jwt-decode'
import { localStorageClear } from '../../utils/utils';


export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};

export const authSuccess = (token) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        token: token
    };
};

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    };
};
export const authRedirect = (data) => {
    return {
        type: actionTypes.AUTH_REDIRECT,
        error: data.msg,
        redirectDomain: {
            status: true,
            url: data.url
        }
    };
};

export const cleanError = () => {
    return {
        type: actionTypes.CLEAN_ERROR,
        error: null
    }
};

export const logout = () => {
    localStorageClear();
    return {
        type: actionTypes.AUTH_LOGOUT
    }
};

export const checkAuthTimeout = (expirationTime) => {
    return dispatch => {
        setTimeout(() => {
            dispatch(logout());
        }, expirationTime * 1000);
    };
};


export const auth = (username, password) => {
    return dispatch => {
        dispatch(authStart());
        const authData = {
            username: username,
            password: password
        };
        let uri = '/auth';

        axios.post(uri, authData)
            .then(response => {
                const decode = jwtDecode(response.data.access_token);
                const expirationDate = new Date(decode.exp * 1000);

                localStorage.setItem('token', response.data.access_token);
                localStorage.setItem('role', decode.user_claims.role);
                localStorage.setItem('provider_id', decode.user_claims.provider_id);
                localStorage.setItem('expirationDate', expirationDate);
                localStorage.setItem('urlVersion', decode.user_claims.url_version || '0.1');

                dispatch(authSuccess(response.data.access_token));
                dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000));
            })
            .catch(err => {
                if (err.response.status === 302){
                    dispatch(authRedirect(err.response.data));
                } else {
                    dispatch(authFail(err.response.data.msg));
                }
            });

    };
};

export const setAuthRedirectPath = (path) => {
    return {
        type: actionTypes.SET_AUTH_REDIRECT_PATH,
        path: path
    };
};

export const clearErrors = () => {
    return dispatch => {
        dispatch(cleanError());
    }
};

export const authCheckState = () => {
    return dispatch => {
        const token = localStorage.getItem('token');
        if (!token) {
            dispatch(logout());
        } else {
            const expirationDate = new Date(localStorage.getItem('expirationDate'));
            if (expirationDate <= new Date()) {
                dispatch(logout());
            } else {
                dispatch(authSuccess(token));
                dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000 ));
            }
        }
    };
};